import { z } from 'zod'

export const descriptionItemSchema = z.object({
  title: z.string().optional(),
  link: z.string().optional(),
})

// This needs to have a catch to all the values, we never want to throw an error here
export const WebchatThemeSchema = z.object({
  color: z.string().optional(),
  variant: z.enum(['soft', 'solid']).optional(),
  themeMode: z.enum(['light', 'dark']).optional(),
  fontFamily: z.enum(['rubik', 'inter', 'ibm', 'fira']).optional(),
  radius: z.number().min(0.5).max(4).optional(),
})
export const configurationSchema = z
  .object({
    composerPlaceholder: z.string().optional(),
    botName: z.string().optional(),
    botAvatar: z.string().optional(),
    botDescription: z.string().optional(),
    website: descriptionItemSchema.optional(),
    email: descriptionItemSchema.optional(),
    phone: descriptionItemSchema.optional(),
    privacyPolicy: descriptionItemSchema.optional(),
    termsOfService: descriptionItemSchema.optional(),
    showPoweredBy: z.boolean().optional(),
  })
  .merge(WebchatThemeSchema)
