import { Button, showConfirmationPrompt } from '@botpress/ui-kit'
import { Flex, Link, Text } from '@radix-ui/themes'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Callout } from '~/elementsv2'
import { IntegrationDetails } from '~/features/integrations/components'
import {
  useMakeIntegrationPublic,
  useRequestIntegrationVerification,
} from '~/hooks/integrations/useIntegrationMutations'
import { integrationQueryOptions } from '~/queries'
import { isBotpressWorkspace } from '~/utils'

export const Route = createFileRoute('/workspaces/$workspaceId/integrations/$integrationId/')({
  component: Component,
})

function Component() {
  const { integrationId, workspaceId } = Route.useParams()
  const integration = useSuspenseQuery(integrationQueryOptions({ integrationId, workspaceId })).data
  const { mutate: publishIntegration, isPending: publishingIntegration } = useMakeIntegrationPublic({
    workspaceId,
    integrationId,
  })
  const { mutate: requestIntegrationVerification } = useRequestIntegrationVerification({ workspaceId, integrationId })

  const bpWorkspace = isBotpressWorkspace(workspaceId)

  const callout = !integration.public ? (
    <Callout
      calloutAction={
        <Button
          className="self-center"
          variant="ghost"
          loading={publishingIntegration}
          onClick={() => {
            void showConfirmationPrompt(
              <Text size={'1'}>
                By publishing content to the Botpress Hub, you acknowledge having read and accepted the Botpress Hub
                Terms. In particular, you warrant that your content is free from any malicious code and is not
                infringing on the rights of others. You will be solely responsible for your content and Botpress or
                Botpress users may seek compensation from you if your content causes damages.&nbsp;
                <Link href="https://botpress.com/legal/hub-terms" target="_blank" rel="noopener noreferrer">
                  Read full terms
                </Link>
              </Text>,
              {
                confirmLabel: 'Publish',
                cancelLabel: 'Cancel',
                title: `Publish ${integration.title} to Botpress Hub`,
              }
            ).then(() => publishIntegration())
          }}
        >
          Make Public
        </Button>
      }
    >
      <Text size={'2'} color={'blue'}>
        This is a <strong>private</strong> integration.
      </Text>
    </Callout>
  ) : integration.verificationStatus === 'unapproved' ? (
    <Callout
      color="amber"
      calloutAction={
        <Button
          color="yellow"
          className="self-center"
          variant="ghost"
          loading={publishingIntegration}
          onClick={() => requestIntegrationVerification()}
        >
          Request verification
        </Button>
      }
    >
      <Text size={'2'} color={'yellow'}>
        This public integration isn't verified.
      </Text>
    </Callout>
  ) : integration.verificationStatus === 'pending' ? (
    <Callout>
      <Text size={'2'} color={'blue'}>
        This public integration verification is <strong>in progress</strong>.
      </Text>
    </Callout>
  ) : integration.verificationStatus === 'rejected' ? (
    <Callout>
      <Text size={'2'} color={'red'}>
        This public integration verification was rejected. Your integration is most likely not working as expected. Make
        sure to fix the issues, publish your changes and resubmit it for verification. For any questions you can{' '}
        <Link href="mailto:hub-applications@botpress.com">Contact us</Link>
      </Text>
    </Callout>
  ) : null

  return (
    <Flex direction={'column'} gap={'2'}>
      {!bpWorkspace && callout}
      <IntegrationDetails integration={integration} />
    </Flex>
  )
}
