import { forwardRef } from 'react'
import { LocationBlock, type CommonBlockProps } from '../../../types'
import { MapPinIcon } from '@heroicons/react/20/solid'
import { webchatClasses } from '../../../styles/classes'

type Props = LocationBlock & CommonBlockProps
export const Location = forwardRef<HTMLAnchorElement, Props>(({ type, latitude, longitude, title, ...props }, ref) => {
  const link = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`

  const {
    message: {
      blocks: { location },
    },
  } = webchatClasses

  return (
    <a {...props} {...location.container} href={link} type="_blank" rel="noopener" ref={ref}>
      <p {...location.title}>{title}</p>
      <MapPinIcon {...location.icon} />
    </a>
  )
})
