/* eslint-disable react-refresh/only-export-components */
import { createStore } from 'zustand'
import type {} from '@redux-devtools/extension' // required for devtools typing
import { devtools } from 'zustand/middleware'
import { createContext, useEffect, type RefObject } from 'react'
import type { Configuration, MessageObject } from '../types'
import type { Renderers } from '../contexts'
import type { WebchatClient } from '../client'
import { useOfflineStore } from './offlineStore'
import { eventEmitter, type EventEmitter, type Events } from '../utils'
import { v4 } from 'uuid'
import { webchatToTarget } from '../adapters'

export type WebchatProps = {
  renderers: Partial<Renderers>
  client?: WebchatClient
  configuration: Configuration
  messages: Record<string, MessageObject[]>
  connected: boolean
  disableComposer: boolean
  isReadOnly: boolean
  isTyping: boolean
  lastTypingHeartbeat: Date | null
  allowFileUpload: boolean
  userData: Record<string, unknown>
  userName?: string
  userPictureUrl?: string
  messageContainerRef: RefObject<HTMLDivElement>
  closeWindow?: () => void | undefined

  /**
   * The conversation ID to use when loading the chat.
   * If not found, a new conversation will be started.
   */
  conversationId?: string
  disableRestartConversation?: boolean
}

export type WebchatState = WebchatProps & {
  headerMessage?: React.ReactNode
  setHeaderMessage: (headerMessage: React.ReactNode) => void
  setState: (newState: Partial<WebchatState>) => void
  setMessages: (messages: MessageObject[]) => void
  addMessage: (message: Omit<MessageObject, 'id'> & { id?: string }) => void
  getMessages: () => MessageObject[]
  sendTextMessage: (message: string) => void
  sendMessage: WebchatClient['sendMessage']
  sendFile: WebchatClient['sendFile']
  on: WebchatClient['on']
  setConnected: (connected: boolean) => void
  setIsTyping: (isTyping: boolean, timeout?: number) => void
  setLastTypingHeartbeat: (lastTypingHeartbeat: Date) => void
  restartConversation: () => void
  setDisableComposer: (disableComposer: boolean) => void
  getClientId: () => string | undefined
  eventEmitter: EventEmitter<Events>
}

export type WebchatStore = ReturnType<typeof useCreateWebchatStore>
export const WebchatContext = createContext<WebchatStore | null>(null)

export const useCreateWebchatStore = (initProps?: Partial<WebchatProps>) => {
  const DEFAULT_PROPS: WebchatProps = {
    configuration: {},
    messages: {},
    connected: true,
    disableComposer: false,
    renderers: {},
    isReadOnly: false,
    userData: {},
    userName: undefined,
    userPictureUrl: undefined,
    closeWindow: undefined,
    messageContainerRef: { current: null },
    isTyping: false,
    lastTypingHeartbeat: null,
    allowFileUpload: false,
    conversationId: undefined,
    disableRestartConversation: false,
  }
  const providedClient = initProps?.client
  const conversationId = initProps?.conversationId

  const setClientMode = useOfflineStore.getState().setClientMode
  const setConversationId = useOfflineStore.getState().setConversationId

  if (providedClient) {
    setClientMode(providedClient.mode)
  }

  useEffect(() => {
    if (conversationId) {
      setConversationId(conversationId)
    }
  }, [conversationId])

  return createStore<WebchatState>()(
    devtools(
      (set, get) => {
        const addMessage = (message: Omit<MessageObject, 'id'> & { id?: string }) => {
          const conversationId = useOfflineStore.getState().conversationId
          if (!conversationId) {
            return
          }
          return set((state) => {
            const messages = state.messages[conversationId] ?? []
            return {
              ...state,
              messages: {
                ...state.messages,
                [conversationId]: [...messages.filter((m) => m.id !== message.id), { id: v4(), ...message }],
              },
            }
          })
        }
        const setMessages = (messages: MessageObject[]) => {
          const conversationId = useOfflineStore.getState().conversationId
          if (!conversationId) {
            return
          }
          return set((state) => {
            return { ...state, messages: { ...state.messages, [conversationId]: messages } }
          })
        }
        const addMessageToHistory = useOfflineStore.getState().addMessageToHistory
        return {
          ...DEFAULT_PROPS,
          ...initProps,
          eventEmitter,
          client: providedClient,
          sendFile: (x: File) => get().client!.sendFile(x),
          sendTextMessage: async (message: string) => {
            const client = get().client
            addMessage({
              id: v4(), // This is temporary untile we fix the client to return the id
              direction: 'outgoing',
              sender: { name: 'You' },
              timestamp: new Date(),
              disableInput: false,
              block: { type: 'bubble', block: { type: 'text', text: message } },
            })
            if (client) {
              await client.sendMessage({ type: 'text', text: message })
            }

            if (client?.userId) {
              addMessageToHistory({ message, userId: client.userId })
            }
          },
          sendMessage: async (message) => {
            const client = get().client
            addMessage({
              id: v4(), // This is temporary untile we fix the client to return the id
              direction: 'outgoing',
              sender: { name: 'You' },
              timestamp: new Date(),
              disableInput: false,
              block: webchatToTarget.messageAdapter(message).payload,
            })
            if (client) {
              await client.sendMessage(message)
            }

            if (client?.userId && message.type === 'text') {
              addMessageToHistory({ message: message.text, userId: client.userId })
            }
          },
          on: (event, callback) => {
            const client = get().client
            return client ? client.on(event, callback) : () => {}
          },
          getClientId: () => get().client?.clientId,
          setHeaderMessage: (headerMessage) => set({ headerMessage }),
          setDisableComposer: (disableComposer) => set({ disableComposer }),
          setState: (newState) => set((state) => ({ ...state, ...newState })),
          setMessages,
          getMessages: () => get().messages[useOfflineStore.getState().conversationId ?? ''] ?? [],
          setIsTyping: (isTyping, timeout) => {
            set((state) => ({ ...state, isTyping }))

            if (!isTyping) {
              return
            }
            set((state) => ({ ...state, lastTypingHeartbeat: new Date() }))

            if (!timeout) {
              return
            }
            setTimeout(() => {
              const lastTypingHeartbeat = get().lastTypingHeartbeat
              if (lastTypingHeartbeat && new Date().getTime() - lastTypingHeartbeat.getTime() > timeout) {
                set((state) => ({ ...state, isTyping: false }))
              }
            }, timeout + 100)
          },
          setLastTypingHeartbeat: (lastTypingHeartbeat) => set((state) => ({ ...state, lastTypingHeartbeat })),
          addMessage,
          setConnected: (connected) => set((state) => ({ ...state, connected })),
          restartConversation: async () => {
            const client = get().client
            const setMessages = get().setMessages
            if (client) {
              await client.newConversation()
              setMessages([])
            }
          },
        }
      },
      { name: 'webchatStore' }
    )
  )
}
