import { Client } from '@bpinternal/webchat-http-client'

export type SignalEmitter = Awaited<ReturnType<Client['listenConversation']>>

export type DisconnectedState = {}
export type UserCreatingState = DisconnectedState & {}
export type UserCreatedState = UserCreatingState & {
  userId: string
  userKey: string
}
export type ConversationCreatingState = UserCreatedState & {}
export type ConversationCreatedState = ConversationCreatingState & {
  conversationId: string
  signalEmitter: SignalEmitter
}

export type PushpinClientState =
  | (DisconnectedState & { status: 'disconnected' })
  | (UserCreatingState & { status: 'user_creating' })
  | (UserCreatedState & { status: 'user_created' })
  | (ConversationCreatingState & { status: 'conversation_creating' })
  | (ConversationCreatedState & { status: 'conversation_created' })

export type PushpinClientStatus = PushpinClientState['status']
export type StateOf<S extends PushpinClientStatus> = Extract<PushpinClientState, { status: S }>

const ranks: Record<PushpinClientStatus, number> = {
  disconnected: 0,
  user_creating: 1,
  user_created: 2,
  conversation_creating: 3,
  conversation_created: 4,
}

type GT<S extends PushpinClientStatus> = S extends 'disconnected'
  ? 'user_creating' | 'user_created' | 'conversation_creating' | 'conversation_created'
  : S extends 'user_creating'
    ? 'user_created' | 'conversation_creating' | 'conversation_created'
    : S extends 'user_created'
      ? 'conversation_creating' | 'conversation_created'
      : S extends 'conversation_creating'
        ? 'conversation_created'
        : never

type LT<S extends PushpinClientStatus> = S extends 'conversation_created'
  ? 'conversation_creating' | 'user_created' | 'user_creating' | 'disconnected'
  : S extends 'conversation_creating'
    ? 'user_created' | 'user_creating' | 'disconnected'
    : S extends 'user_created'
      ? 'user_creating' | 'disconnected'
      : S extends 'user_creating'
        ? 'disconnected'
        : never

export const isEq = <S extends PushpinClientStatus>(s: PushpinClientState, target: S): s is StateOf<S> => {
  return s.status === target
}
export const isGt = <S extends PushpinClientStatus>(s: PushpinClientState, target: S): s is StateOf<GT<S>> => {
  return ranks[s.status] > ranks[target]
}
export const isLt = <S extends PushpinClientStatus>(s: PushpinClientState, target: S): s is StateOf<LT<S>> => {
  return ranks[s.status] < ranks[target]
}
export const isGte = <S extends PushpinClientStatus>(s: PushpinClientState, target: S): s is StateOf<GT<S> | S> => {
  return ranks[s.status] >= ranks[target]
}
export const isLte = <S extends PushpinClientStatus>(s: PushpinClientState, target: S): s is StateOf<LT<S> | S> => {
  return ranks[s.status] <= ranks[target]
}

export class InvalidStateError extends Error {
  public constructor(public readonly state: PushpinClientState) {
    super(`Unexpected state: ${state.status}`)
  }
}

type _Lt0 = StateOf<LT<'disconnected'>>['status']
type _Lt1 = StateOf<LT<'user_creating'>>['status']
type _Lt2 = StateOf<LT<'user_created'>>['status']
type _Lt3 = StateOf<LT<'conversation_creating'>>['status']
type _Lt4 = StateOf<LT<'conversation_created'>>['status']
type _Gt0 = StateOf<GT<'disconnected'>>['status']
type _Gt1 = StateOf<GT<'user_creating'>>['status']
type _Gt2 = StateOf<GT<'user_created'>>['status']
type _Gt3 = StateOf<GT<'conversation_creating'>>['status']
type _Gt4 = StateOf<GT<'conversation_created'>>['status']
