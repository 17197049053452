import { ArrowPathIcon } from '@heroicons/react/20/solid'
import { ComponentProps } from 'react'
import { useModalContext } from '../contexts'
import { Modal } from '.'
import { useWebchatStore } from '../hooks'
import { webchatClasses } from '../styles/classes'

type Props = {
  // onConfirm: () => void
} & ComponentProps<typeof ArrowPathIcon>

export const RestartConversation = ({ ...props }: Props) => {
  //TODO: Fix this, this was done quick to avoid the app component re-rendering

  const { header, modal } = webchatClasses
  const restartConversation = useWebchatStore((s) => s.restartConversation)

  const { showModal } = useModalContext()

  const onSelect = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    showModal({
      title: 'Restart Conversation',
      content: (
        <>
          <p {...modal?.dialog?.newConversation?.text}>Are you sure you want to restart a new conversation?</p>
          <Modal.Close asChild>
            <button type="button" {...modal?.dialog?.newConversation?.button} onClick={restartConversation}>
              Restart
            </button>
          </Modal.Close>
        </>
      ),
    })
  }
  return (
    <ArrowPathIcon
      {...props}
      {...header?.content?.actions?.icons}
      role="button"
      tabIndex={0}
      onClick={onSelect}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelect(e)
        }
      }}
    />
  )
}
