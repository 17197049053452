import { WebchatClient } from './client'

type GetClientOptions = {
  clientId: string
  apiUrl?: string
}

export const getClient = ({ clientId, apiUrl = 'https://webchat.botpress.cloud' }: GetClientOptions): WebchatClient =>
  new WebchatClient({
    apiUrl,
    clientId,
  })
