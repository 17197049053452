import { ComponentProps, useEffect, useState } from 'react'
import { ButtonBlock, type CommonBlockProps } from '../../../types'
import { useWebchatStore } from '../../../hooks'
import { webchatClasses } from '../../../styles/classes'

type Props = ButtonBlock & CommonBlockProps
export const Button = ({ text, buttonValue, type, variant, groupId, reusable, ...props }: Props) => {
  const eventEmitter = useWebchatStore((s) => s.eventEmitter)
  const sendTextMessage = useWebchatStore((s) => s.sendTextMessage)

  const isReadOnly = useWebchatStore((s) => s.isReadOnly)

  const [activated, setActivated] = useState(false)
  const [groupActivated, setGroupActivated] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const {
    message: {
      blocks: { button },
    },
  } = webchatClasses

  useEffect(() => {
    if (!groupId) return
    return eventEmitter.on(`button-group-${groupId}-click`, () => {
      setGroupActivated(true)
      if (!reusable) setDisabled(true)
    })
  }, [eventEmitter, groupId, reusable])

  function onClick() {
    if (groupId) {
      eventEmitter.emit(`button-group-${groupId}-click`)
    }
    setActivated(true)
    if (!reusable) setDisabled(true)
    if (variant === 'link') return
    void sendTextMessage(buttonValue)
  }

  return (
    <ButtonOrAnchor
      {...props}
      variant={variant}
      onClick={onClick}
      disabled={disabled || isReadOnly}
      data-activated={activated ? '' : undefined}
      data-group-activated={groupActivated ? '' : undefined}
      data-type={variant}
      value={buttonValue}
      {...button}
    >
      {text}
    </ButtonOrAnchor>
  )
}
type ButtonOrAnchorProps =
  | ({ variant: 'action' } & ComponentProps<'button'>)
  | ({ variant: 'link'; value: string } & ComponentProps<'a'>)
const ButtonOrAnchor = (props: ButtonOrAnchorProps) => {
  if (props.variant === 'link') {
    const { value, variant, ...rest } = props
    return <a {...rest} href={value} target="_blank" rel="noopener noreferrer" />
  }
  const { variant, ...rest } = props
  return <button type="button" {...rest} />
}
