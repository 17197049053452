import { ComponentProps, forwardRef } from 'react'
import { ColumnBlock, type CommonBlockProps } from '../../../types'
import { Block } from '../../Block'
import { webchatClasses } from '../../../styles/classes'

type Props = ColumnBlock & ComponentProps<'div'> & CommonBlockProps
export const Column = forwardRef<HTMLDivElement, Props>(
  ({ blocks, type, horizontalAlignment, verticalAlignment, direction, sender, timestamp, metadata, ...props }, ref) => {
    const {
      message: {
        blocks: { column },
      },
    } = webchatClasses

    return (
      <div data-horizontal={horizontalAlignment} data-vertical={verticalAlignment} {...props} {...column} ref={ref}>
        {blocks.map((block, index) => (
          <Block key={index} {...{ block, direction, sender, timestamp, metadata }} />
        ))}
      </div>
    )
  }
)
