import * as Dialog from '@radix-ui/react-dialog'
import { ReactNode } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { webchatClasses } from '../../styles/classes'

export function Modal({
  open,
  onOpenChange,
  children,
}: {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  children: ReactNode
}) {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {children}
    </Dialog.Root>
  )
}

function ModalContent({ title, children }: { title: string; children: ReactNode }) {
  const { modal } = webchatClasses

  return (
    <>
      <Dialog.Overlay {...modal?.overlay} />
      <div {...modal?.container}>
        <Dialog.Content {...modal?.dialog?.container}>
          <div {...modal?.dialog?.title?.container}>
            <Dialog.Title {...modal?.dialog?.title?.text}>{title}</Dialog.Title>
            <Dialog.Close>
              <XMarkIcon role="button" tabIndex={0} {...modal?.dialog?.title?.closeIcon} />
            </Dialog.Close>
          </div>
          <div {...modal?.dialog?.content}>{children}</div>
        </Dialog.Content>
      </div>
    </>
  )
}

Modal.Button = Dialog.Trigger
Modal.Close = Dialog.Close
Modal.Content = ModalContent
