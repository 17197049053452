import { forwardRef } from 'react'
import { AudioBlock, type CommonBlockProps } from '../../../types'
import { webchatClasses } from '../../../styles/classes'

type Props = AudioBlock & CommonBlockProps
export const Audio = forwardRef<HTMLAudioElement, Props>(({ url, type, ...props }, ref) => {
  const {
    message: {
      blocks: { audio },
    },
  } = webchatClasses
  return (
    <div>
      <audio {...props} controls src={url} {...audio} ref={ref} />
    </div>
  )
})
