import * as uuid from 'uuid'
import * as target from './target'
import * as webchat from './webchat'

type WithBubble<T extends target.Message> = target.BubbleOf<T>

type WebchatCardMessage = webchat.Messages['card']

type AdapterOutput = {
  payload: target.Message
  disableInput?: boolean
}
type WebchatToTargetAdapters = {
  [Type in keyof webchat.Messages]: (message: webchat.Messages[Type]) => AdapterOutput
}

/**
 * Equivalent of `Omit<CardMessage, 'type'>`
 * Omit does not behave as expected when type contains string index signature
 */
type WebchatCard = {
  [K in keyof WebchatCardMessage as K extends 'type' ? never : K]: WebchatCardMessage[K]
}

const withBubble = <T extends target.Message>(block: T): WithBubble<T> => {
  return {
    block,
    type: 'bubble',
  }
}

const mapCard = (x: WebchatCard): target.ColumnMessage => {
  const title = x.title
  const subtitle = x.subtitle
  const image = x.imageUrl
  const actions: target.ButtonMessage[] = x.actions.map((a) => {
    switch (a.action) {
      case 'postback':
        return {
          type: 'button',
          variant: 'action',
          text: a.label,
          buttonValue: a.value,
          reusable: true,
        }
      case 'url':
        return {
          type: 'button',
          variant: 'link',
          text: a.label,
          buttonValue: a.value,
          reusable: true,
        }
      case 'say':
        return {
          type: 'button',
          variant: 'action',
          text: a.label,
          buttonValue: a.value,
          reusable: true,
        }
      default:
        throw new Error(`Unknown action type ${a.action}`)
    }
  })

  const cardBlocks: (target.ImageMessage | target.TextMessage | target.RowOf<target.ButtonMessage>)[] = []

  if (image) {
    cardBlocks.push({
      type: 'image',
      url: image,
    })
  }

  if (title) {
    cardBlocks.push({
      type: 'text',
      text: `## ${title}`,
    })
  }

  if (subtitle) {
    cardBlocks.push({
      type: 'text',
      text: subtitle,
    })
  }

  if (actions.length > 0) {
    cardBlocks.push({
      type: 'row',
      blocks: actions,
    })
  }

  return {
    type: 'column',
    horizontalAlignment: 'center',
    blocks: [...cardBlocks],
  }
}

const audioAdapter = ((x) => {
  return {
    payload: { type: 'audio', url: x.audioUrl },
  }
}) satisfies WebchatToTargetAdapters['audio']

const cardAdapter = ((x) => {
  return {
    payload: withBubble(mapCard(x)),
  }
}) satisfies WebchatToTargetAdapters['card']

const carouselAdapter = ((x) => {
  return {
    payload: {
      type: 'carousel',
      blocks: x.items.map((i) => mapCard(i)),
    },
  }
}) satisfies WebchatToTargetAdapters['carousel']

const choiceAdapter = ((x) => {
  const choices = x.options.map((option) => ({ title: option.label, value: option.value }))
  const text = x.text

  const groupId = uuid.v4()

  const textBlock = withBubble({
    type: 'text',
    text,
  })

  if (choices.length === 0) {
    return {
      disableInput: x.disableFreeText,
      payload: textBlock,
    }
  }

  return {
    disableInput: x.disableFreeText,
    payload: {
      type: 'column',
      horizontalAlignment: 'left',
      blocks: [
        textBlock,
        {
          type: 'row',
          blocks: choices.map(({ title, value }) => ({
            type: 'button',
            variant: 'action',
            text: title,
            buttonValue: value,
            groupId,
          })),
        },
      ],
    },
  }
}) satisfies WebchatToTargetAdapters['choice']

const dropdownAdapter = ((x) => {
  const options = x.options.map((c) => ({ label: c.label, value: c.value }))
  const text = x.text
  const blocks: (target.DropdownMessage | target.TextMessage)[] = [
    {
      type: 'dropdown',
      label: text ?? 'Select an option',
      options,
    },
  ]

  if (text) {
    blocks.unshift({
      type: 'text',
      text,
    })
  }

  return {
    disableInput: true,
    payload: withBubble({
      type: 'column',
      blocks,
    }),
  }
}) satisfies WebchatToTargetAdapters['dropdown']

const fileAdapter = ((x) => {
  return {
    payload: {
      type: 'file',
      url: x.fileUrl,
      title: x.title,
    },
  }
}) satisfies WebchatToTargetAdapters['file']

const imageAdapter = ((x) => {
  return {
    payload: { type: 'image', url: x.imageUrl },
  }
}) satisfies WebchatToTargetAdapters['image']

const locationAdapter = ((x) => {
  return {
    payload: {
      type: 'location',
      latitude: x.latitude,
      longitude: x.longitude,
      title: x.title ?? x.address ?? 'View on map',
    },
  }
}) satisfies WebchatToTargetAdapters['location']

const markdownAdapter = ((x) => {
  // TODO: previous implementation did not use `markdown: true` option, should we add something?
  return {
    payload: withBubble({ type: 'text', text: x.markdown }),
  }
}) satisfies WebchatToTargetAdapters['markdown']

const textAdapter = ((x) => {
  return {
    payload: withBubble({ type: 'text', text: x.text }),
  }
}) satisfies WebchatToTargetAdapters['text']

const videoAdapter = ((x) => {
  return {
    payload: { type: 'video', url: x.videoUrl },
  }
}) satisfies WebchatToTargetAdapters['video']

const blocAdapter = ((x) => {
  const output: target.ColumnMessage = {
    type: 'column',
    blocks: x.items.map((item) => {
      switch (item.type) {
        case 'audio':
          return audioAdapter({ ...item.payload, type: item.type }).payload
        case 'file':
          return fileAdapter({ ...item.payload, type: item.type }).payload
        case 'image':
          return imageAdapter({ ...item.payload, type: item.type }).payload
        case 'location':
          return locationAdapter({ ...item.payload, type: item.type }).payload
        case 'markdown':
          return markdownAdapter({ ...item.payload, type: item.type }).payload
        case 'text':
          return textAdapter({ ...item.payload, type: item.type }).payload
        case 'video':
          return videoAdapter({ ...item.payload, type: item.type }).payload
        default:
          throw new Error('Unsuported message type')
      }
    }),
  }
  return { payload: output }
}) satisfies WebchatToTargetAdapters['bloc']

export const messageAdapter = (message: webchat.Message): AdapterOutput => {
  switch (message.type) {
    case 'audio':
      return audioAdapter(message)
    case 'card':
      return cardAdapter(message)
    case 'carousel':
      return carouselAdapter(message)
    case 'choice':
      return choiceAdapter(message)
    case 'dropdown':
      return dropdownAdapter(message)
    case 'file':
      return fileAdapter(message)
    case 'image':
      return imageAdapter(message)
    case 'location':
      return locationAdapter(message)
    case 'markdown':
      return markdownAdapter(message)
    case 'text':
      return textAdapter(message)
    case 'video':
      return videoAdapter(message)
    case 'bloc':
      return blocAdapter(message)
    default:
      throw new Error('Unsuported message type')
  }
}
