import { getColors } from 'theme-colors'
import { WebchatThemeSchema } from '../schemas'
import { apcaContrast, colorToGray, generateCssString, hexToRgb, reversePalette, rgbToHex } from '.'
import type { WebchatTheme } from '../types'

export function generateThemeStylesheet(theme: WebchatTheme): string {
  const { fontFamily, radius, themeMode, variant, color } = WebchatThemeSchema.parse(theme)

  let themeOverride: Record<string, string> = {}
  let primaryPalette: Record<string, string> = {}

  if (color) {
    const primaryColors = getColors(color)
    const colors = themeMode === 'dark' ? reversePalette(primaryColors) : primaryColors

    const grayColors = getColors(rgbToHex(colorToGray(hexToRgb(color))))
    const grays = themeMode === 'dark' ? reversePalette(grayColors) : grayColors

    const backgroundColor = themeMode === 'dark' ? '#101010' : '#fdfdfd'

    primaryPalette = Object.entries({ ...colors, '1': backgroundColor }).reduce(
      (acc, [key, value]) => {
        return { ...acc, [`--bpPrimary-${key}`]: value }
      },
      {} as Record<string, string>
    )
    const grayPalette = Object.entries({ ...grays, '1': backgroundColor }).reduce(
      (acc, [key, value]) => {
        return { ...acc, [`--bpGray-${key}`]: value }
      },
      {} as Record<string, string>
    )

    themeOverride = {
      ...primaryPalette,
      ...grayPalette,
    }
  }

  if (radius) {
    themeOverride = {
      ...themeOverride,
      ...{ '--bpRadius-scale': radius.toString() },
    }
  }

  const onSolidTextColor = primaryPalette
    ? apcaContrast(primaryPalette['--bpPrimary-600'], primaryPalette['--bpPrimary-50']) > 40
      ? 'var(--bpPrimary-50)'
      : 'var(--bpPrimary-900)'
    : 'var(--bpPrimary-50)'

  const variantVariables: Record<`--${string}`, string> =
    variant === 'solid'
      ? {
          '--header-bg': 'var(--bpPrimary-600)',
          '--header-bg-hover': 'var(--bpPrimary-700)',
          '--header-hover-dark': 'var(--bpPrimary-800)',
          '--header-title': onSolidTextColor,
          '--header-description': onSolidTextColor,
          '--header-description-icon': onSolidTextColor,
          '--header-description-link': onSolidTextColor,
          '--header-avatar-bg': 'var(--bpPrimary-300)',
          '--header-avatar-text': 'var(--bpPrimary-700)',
          '--message-bg': 'var(--bpPrimary-600)',
          '--message-bg-hover': 'var(--bpPrimary-700)',
          '--message-text': onSolidTextColor,
          '--send-button-bg': 'var(--bpPrimary-600)',
          '--send-button-bg-hover': 'var(--bpPrimary-700)',
          '--send-button-text': 'var(--bpPrimary-50)',
          '--button-bg': 'var(--bpPrimary-50)',
          '--button-bg-hover': 'var(--bpPrimary-100)',
          '--button-text': 'var(--bpPrimary-800)',
          '--fab-bg': 'var(--bpPrimary-600)',
          '--fab-bg-hover': 'var(--bpPrimary-700)',
          '--fab-icon': onSolidTextColor,
        }
      : {
          '--header-bg': 'var(--bpGray-50)',
          '--header-bg-hover': 'var(--bpGray-100)',
          '--header-hover-dark': 'var(--bpGray-200)',
          '--header-title': 'var(--bpGray-900)',
          '--header-description': 'var(--bpGray-800)',
          '--header-description-icon': 'var(--bpPrimary-700)',
          '--header-description-link': 'var(--bpPrimary-800)',
          '--header-avatar-bg': 'var(--bpPrimary-600)',
          '--header-avatar-text': 'var(--bpPrimary-50)',
          '--message-bg': 'var(--bpPrimary-100)',
          '--message-bg-hover': 'var(--bpPrimary-200)',
          '--message-text': 'var(--bpPrimary-900)',
          '--send-button-bg': 'var(--bpPrimary-600)',
          '--send-button-bg-hover': 'var(--bpPrimary-700)',
          '--send-button-text': 'var(--bpPrimary-50)',
          '--button-bg': 'var(--bpPrimary-500)',
          '--button-bg-hover': 'var(--bpPrimary-600)',
          '--button-text': primaryPalette
            ? apcaContrast(primaryPalette['--bpPrimary-500'], primaryPalette['--bpPrimary-50']) > 40
              ? 'var(--bpPrimary-50)'
              : 'var(--bpPrimary-900)'
            : 'var(--bpPrimary-50)',
          '--fab-bg': 'var(--bpPrimary-100)',
          '--fab-bg-hover': 'var(--bpPrimary-200)',
          '--fab-icon': 'var(--bpPrimary-700)',
        }

  themeOverride = {
    ...themeOverride,
    ...variantVariables,
  }

  return (
    generateCssString(':root', themeOverride) +
    (fontFamily ? generateCssString('.bpContainer', { 'font-family': `var(--${fontFamily}-font)` }) : '')
  )
}
