import { Client, isApiError, Message as BotpressMessage, ApiError } from '@bpinternal/webchat-http-client'
import { EventEmitter } from '../utils'
import { Events, UserCredentials, UserData, UserOptions, type Message, type User } from './types'
import * as sm from './state-machine'
import { webchatToTarget } from '../adapters'
import { UserProps, isFileType, type FileType } from '../types'
import type { Message as WebchatMessage } from '../adapters/webchat'

// stream will close if ping or signal is not received within 60s
const SSE_CONNECTION_TIMEOUT = 60_000

export type PushpinClientProps = {
  apiUrl: string
  clientId: string
}

export class WebchatClient extends EventEmitter<Events> {
  private _client: Client
  private _webhookId: string
  private _apiUrl: string

  private _state: sm.PushpinClientState = { status: 'disconnected' }

  public constructor(props: PushpinClientProps) {
    super()
    const apiUrl = `${props.apiUrl}/${props.clientId}`
    this._webhookId = props.clientId
    this._apiUrl = apiUrl
    this._client = new Client({
      apiUrl,
      sseTimeout: SSE_CONNECTION_TIMEOUT,
    })
  }

  public readonly mode = 'pushpin'

  public get apiUrl() {
    return this._apiUrl
  }

  public get clientId() {
    return this._webhookId
  }

  public get userId() {
    if (sm.isLt(this._state, 'user_created')) {
      return
    }
    return this._state.userId
  }

  public async getUser() {
    if (sm.isLt(this._state, 'user_created')) {
      throw new sm.InvalidStateError(this._state)
    }

    const { user } = await this._client.getUser({ 'x-user-key': this._state.userKey })

    return { data: user.data }
  }

  public async updateUser(user: User): Promise<User> {
    if (sm.isLt(this._state, 'user_created')) {
      throw new sm.InvalidStateError(this._state)
    }

    const { user: updatedUser } = await this._client.updateUser({
      'x-user-key': this._state.userKey,
      name: user.name,
      pictureUrl: user.pictureUrl,
      userData: user.data,
    })

    return updatedUser
  }

  public get conversationId() {
    if (sm.isLt(this._state, 'conversation_created')) {
      return
    }
    return this._state.conversationId
  }

  public async connect(
    creds?: UserCredentials,
    data?: UserData,
    opts: UserOptions = {}
  ): Promise<UserCredentials | undefined> {
    const props: UserProps = { data, ...opts }
    if (!creds) {
      return this._initialConnect(props)
    }
    const userExists = await this.userExists(creds)
    if (!userExists) {
      return this._initialConnect(props)
    }
    return this._reConnect(creds, props)
  }

  private _initialConnect = async ({ data, name, pictureUrl }: UserProps): Promise<UserCredentials | undefined> => {
    if (sm.isGte(this._state, 'user_created')) {
      throw new Error('Client is already connected. Please disconnect first.')
    }
    if (sm.isEq(this._state, 'user_creating')) {
      return // already connecting
    }

    this._state = {
      status: 'user_creating',
    }

    const {
      user: { id: userId },
      key: userKey,
    } = await this._client.createUser({
      name,
      pictureUrl,
      userData: data,
    })

    this._state = {
      status: 'user_created',
      userId,
      userKey,
    }

    return {
      userId,
      userToken: userKey,
    }
  }

  private _reConnect = async (
    creds: UserCredentials,
    { data, name, pictureUrl }: UserProps
  ): Promise<UserCredentials> => {
    if (sm.isGte(this._state, 'user_created')) {
      if (this._state.userId !== creds.userId) {
        throw new Error('Client is already connected. Please disconnect first.')
      }
      return creds
    }

    if (sm.isEq(this._state, 'user_creating')) {
      return creds
    }

    this._state = {
      status: 'user_created',
      userId: creds.userId,
      userKey: creds.userToken,
    }

    await this._client.updateUser({
      'x-user-key': creds.userToken,
      name,
      pictureUrl,
      userData: data,
    })

    return creds
  }

  public async disconnect(): Promise<void> {
    if (sm.isEq(this._state, 'conversation_created')) {
      this._state.signalEmitter.cleanup()
    } else if (sm.isEq(this._state, 'conversation_creating')) {
      console.warn('zombie conversation...')
    }
    this._state = { status: 'disconnected' }
  }

  public async sendFile(file: File): Promise<{ fileUrl: string; name: string; type: FileType }> {
    if (sm.isLt(this._state, 'user_created')) {
      throw new sm.InvalidStateError(this._state)
    }
    if (sm.isEq(this._state, 'conversation_creating')) {
      throw new sm.InvalidStateError(this._state) // must wait for conversation to be created
    }

    let state: sm.ConversationCreatedState
    if (sm.isEq(this._state, 'conversation_created')) {
      state = this._state
    } else {
      this._state = { status: 'conversation_creating', userId: this._state.userId, userKey: this._state.userKey }
      state = await this._createNewConversation(this._state)
    }

    const { size, name } = file

    const getFileBuffer = (file: File): Promise<ArrayBuffer> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => resolve(event.target?.result as ArrayBuffer)
        reader.onerror = (event) => reject(event.target?.error)
        reader.readAsArrayBuffer(file)
      })
    }

    const buffer = await getFileBuffer(file)

    const {
      file: { uploadUrl, contentType: mimeType, url: fileUrl },
    } = await this._client.createFile({
      'x-user-key': state.userKey,
      size,
      key: name,
      accessPolicies: ['public_content'],
      index: false,
      tags: { source: 'integration', integrationName: 'webchat' },
    })
    await fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'x-amz-tagging': 'public=true',
      },
      body: buffer,
    })

    const contentType = mimeType.split('/').shift() ?? ''
    const fileType = isFileType(contentType) ? contentType : ('file' as const)

    type CreateMessagePayload = Parameters<typeof this._client.createMessage>[0]['payload']

    const payload: CreateMessagePayload =
      fileType === 'image'
        ? { type: fileType, imageUrl: fileUrl }
        : fileType === 'audio'
          ? { type: fileType, audioUrl: fileUrl }
          : fileType === 'video'
            ? { type: fileType, videoUrl: fileUrl }
            : { type: fileType, fileUrl }

    await this._client.createMessage({
      'x-user-key': state.userKey,
      conversationId: state.conversationId,
      payload,
    })

    this.emit('messageSent', payload)
    return { fileUrl, name, type: fileType }
  }

  public async sendMessage(payload: WebchatMessage): Promise<void> {
    if (sm.isLt(this._state, 'user_created')) {
      throw new sm.InvalidStateError(this._state)
    }
    if (sm.isEq(this._state, 'conversation_creating')) {
      throw new sm.InvalidStateError(this._state) // must wait for conversation to be created
    }

    let state: sm.ConversationCreatedState
    if (sm.isEq(this._state, 'conversation_created')) {
      state = this._state
    } else {
      this._state = { status: 'conversation_creating', userId: this._state.userId, userKey: this._state.userKey }
      state = await this._createNewConversation(this._state)
    }

    await this._client.createMessage({
      'x-user-key': state.userKey,
      conversationId: state.conversationId,
      payload,
    })

    this.emit('messageSent', payload)
  }

  public async sendEvent(event: Record<string, any>): Promise<void> {
    if (sm.isLt(this._state, 'user_created')) {
      throw new sm.InvalidStateError(this._state)
    }
    if (sm.isEq(this._state, 'conversation_creating')) {
      throw new sm.InvalidStateError(this._state) // must wait for conversation to be created
    }

    let state: sm.ConversationCreatedState
    if (sm.isEq(this._state, 'conversation_created')) {
      state = this._state
    } else {
      this._state = { status: 'conversation_creating', userId: this._state.userId, userKey: this._state.userKey }
      state = await this._createNewConversation(this._state)
    }

    await this._client.createEvent({
      'x-user-key': state.userKey,
      conversationId: state.conversationId,
      payload: {
        type: 'custom',
        data: event,
      },
    })
  }

  public async switchConversation(id: string): Promise<void> {
    if (sm.isLt(this._state, 'user_created')) {
      throw new sm.InvalidStateError(this._state)
    }
    if (sm.isEq(this._state, 'conversation_created') && this._state.conversationId === id) {
      return // nothing to do here
    }
    if (sm.isEq(this._state, 'conversation_creating')) {
      return // already switching or creating
    }

    this._state = {
      status: 'conversation_creating',
      userId: this._state.userId,
      userKey: this._state.userKey,
    }
    await this._connectConversation(this._state, id)
  }

  public async conversationExists(id: string): Promise<boolean> {
    if (sm.isLt(this._state, 'user_created')) {
      return false // even if the conversation exists, we don't have access to it
    }

    try {
      await this._client.getConversation({
        'x-user-key': this._state.userKey,
        id,
      })
      return true
    } catch (thrown) {
      if (isApiError(thrown) && thrown.code === 404) {
        return false
      }
      throw thrown
    }
  }

  public async userExists({ userToken }: UserCredentials): Promise<boolean> {
    try {
      await this._client.getUser({
        'x-user-key': userToken,
      })
      return true
    } catch (thrown) {
      const isNotExist = (err: ApiError) => err.code === 404 || err.code === 401
      if (isApiError(thrown) && isNotExist(thrown)) {
        return false
      }
      throw thrown
    }
  }

  public async newConversation(): Promise<void> {
    if (sm.isLt(this._state, 'user_created')) {
      throw new sm.InvalidStateError(this._state)
    }
    if (sm.isEq(this._state, 'conversation_creating')) {
      return // already switching or creating
    }

    this._state = { status: 'conversation_creating', userId: this._state.userId, userKey: this._state.userKey }
    await this._createNewConversation(this._state)
  }

  private async _createNewConversation(state: sm.ConversationCreatingState): Promise<sm.ConversationCreatedState> {
    const {
      conversation: { id: conversationId },
    } = await this._client.createConversation({ 'x-user-key': state.userKey })

    const newState = await this._connectConversation(state, conversationId)

    await this._client.createEvent({
      'x-user-key': state.userKey,
      conversationId,
      payload: { type: 'conversation_started', data: {} },
    })

    return newState
  }

  public async listMessages(): Promise<Message[]> {
    if (!sm.isEq(this._state, 'conversation_created')) {
      return []
    }

    const { conversationId, userKey } = this._state

    const allMessages: BotpressMessage[] = []
    let nextToken: string | undefined = undefined
    do {
      const resp = await this._client.listConversationMessages({ id: conversationId, 'x-user-key': userKey, nextToken })
      allMessages.push(...resp.messages)
      nextToken = resp.meta.nextToken
    } while (nextToken)

    return allMessages.map(this._mapMessage)
  }

  private async _connectConversation(
    state: sm.ConversationCreatingState,
    conversationId: string
  ): Promise<sm.ConversationCreatedState> {
    const signalEmitter = await this._client.listenConversation({ id: conversationId, 'x-user-key': state.userKey })
    signalEmitter.on('unknown', (ev) => {
      if (typeof ev === 'string' && ev === 'ping') {
        return
      }
      console.debug('unknown event', ev)
    })
    signalEmitter.on('message_created', (ev) => {
      if (ev.userId === state.userId) {
        return
      }
      this.emit('message', this._mapMessage(ev))
    })
    signalEmitter.on('error', (err) => {
      this._state = { status: 'user_created', userId: state.userId, userKey: state.userKey }
      this.emit('error', new Error(`Connection to conversation lost: ${err.message}`))
    })
    signalEmitter.on('webchat_visibility', (ev) => {
      this.emit('webchatVisibility', ev.visibility)
    })
    signalEmitter.on('webchat_config', (ev) => {
      this.emit('webchatConfig', ev.config)
    })
    signalEmitter.on('typing_started', (ev) => {
      this.emit('isTyping', { isTyping: true, timeout: ev.timeout ?? 5000 })
    })
    signalEmitter.on('typing_stopped', () => {
      this.emit('isTyping', { isTyping: false, timeout: 0 })
    })
    signalEmitter.on('custom', (ev) => {
      this.emit('customEvent', ev.event)
    })

    this.emit('conversation', conversationId)

    this._state = {
      status: 'conversation_created',
      userId: state.userId,
      userKey: state.userKey,
      conversationId,
      signalEmitter,
    }

    return this._state
  }

  private _mapMessage = (message: BotpressMessage): Message => {
    const { metadata } = message
    const { payload, disableInput } = webchatToTarget.messageAdapter(message.payload)

    return {
      id: message.id,
      conversationId: message.conversationId,
      authorId: message.userId,
      sentOn: new Date(message.createdAt),
      payload,
      disableInput,
      metadata,
    }
  }
}
