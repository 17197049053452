import { ComponentProps, memo } from 'react'
import { webchatClasses } from '../../styles/classes'

export const TypingIndicator = memo(({ ...props }: ComponentProps<'div'>) => {
  const { typingIndicator } = webchatClasses

  return (
    <div {...props} {...typingIndicator?.container}>
      <div {...typingIndicator?.loader} />
    </div>
  )
})
