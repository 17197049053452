import { ComponentProps, forwardRef, useEffect } from 'react'
import { useModalContext } from '../../contexts'
import { Toaster } from 'react-hot-toast'
import { ModalProvider } from '../../providers'
import { Modal } from '../Modal'
import { useWebchatStore } from '../../hooks/useWebchatStore'
import { webchatClasses } from '../../styles/classes'
import { clsx } from 'clsx'

export const Container = forwardRef<HTMLDivElement, ComponentProps<'div'>>(({ children, className, ...props }, ref) => {
  const {
    container: { className: classes },
  } = webchatClasses

  return (
    <div {...props} className={clsx(classes, className)} ref={ref}>
      <Toaster />
      <ModalProvider>
        <ConnectionModal />
        {children}
      </ModalProvider>
    </div>
  )
})

const ConnectionModal = () => {
  const { modal } = webchatClasses
  const connected = useWebchatStore((s) => s.connected)

  const { showModal } = useModalContext()

  useEffect(() => {
    if (!connected) {
      showModal({
        title: 'Connection Error',
        content: (
          <>
            <p {...modal?.dialog?.newConversation?.text}>
              There was an error connecting to the server. Please check your internet connection and try again.
            </p>
            <Modal.Close asChild>
              <button
                type="button"
                {...modal?.dialog?.newConversation?.button}
                onClick={() => window.location.reload()}
              >
                Restart
              </button>
            </Modal.Close>
          </>
        ),
      })
    }
  }, [connected])

  return <></>
}
