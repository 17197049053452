import { ComponentProps, forwardRef } from 'react'
import { BubbleBlock, type CommonBlockProps } from '../../../types'
import { Block } from '../../Block'
import { webchatClasses } from '../../../styles/classes'

type Props = BubbleBlock & ComponentProps<'div'> & CommonBlockProps
export const Bubble = forwardRef<HTMLDivElement, Props>(
  ({ block, type, direction, timestamp, sender, metadata, ...props }, ref) => {
    const {
      message: {
        blocks: { bubble },
      },
    } = webchatClasses

    return (
      <div {...props} {...bubble} ref={ref}>
        <Block {...{ direction, timestamp, sender, block, metadata }} />
      </div>
    )
  }
)
