import { z } from 'zod'
import { CSSProperties } from 'react'

export const markdownTypeSchema = z.enum([
  'heading1',
  'heading2',
  'heading3',
  'text',
  'horizontalRule',
  'link',
  'italic',
  'bold',
  'orderedList',
  'unorderedList',
  'listItem',
  'lineBreak',
  'pre',
])

export const cssStyleSchema = z
  .object({})
  .passthrough()
  .transform((v) => v as CSSProperties)

export const styleOptionSchema = z.object({
  className: z.string().optional(),
  style: cssStyleSchema.optional(),
})

export const avatarThemeSchema = z.object({
  container: styleOptionSchema.optional(),
  image: styleOptionSchema.optional(),
  fallback: styleOptionSchema.optional(),
})

export const blockThemeSchema = z.object({
  button: styleOptionSchema.optional(),
  text: z.record(markdownTypeSchema, styleOptionSchema.optional()),
  image: z
    .object({
      image: styleOptionSchema.optional(),
      placeholder: styleOptionSchema.optional(),
    })
    .optional(),
  audio: styleOptionSchema.optional(),
  video: styleOptionSchema.optional(),
  file: z
    .object({
      container: styleOptionSchema.optional(),
      title: styleOptionSchema.optional(),
      icon: styleOptionSchema.optional(),
    })
    .optional(),
  location: z
    .object({
      container: styleOptionSchema.optional(),
      title: styleOptionSchema.optional(),
      icon: styleOptionSchema.optional(),
    })
    .optional(),
  column: styleOptionSchema.optional(),
  row: styleOptionSchema.optional(),
  bubble: styleOptionSchema.optional(),
  carousel: z
    .object({
      container: styleOptionSchema.optional(),
      slidesContainer: styleOptionSchema.optional(),
      backButton: styleOptionSchema.optional(),
      nextButton: styleOptionSchema.optional(),
    })
    .optional(),
  dropdown: z
    .object({
      button: z
        .object({
          container: styleOptionSchema.optional(),
          icon: styleOptionSchema.optional(),
          text: styleOptionSchema.optional(),
        })
        .optional(),
      content: z
        .object({
          container: styleOptionSchema.optional(),
          item: styleOptionSchema.optional(),
        })
        .optional(),
    })
    .optional(),
})

export const themeSchema = z.object({
  container: styleOptionSchema.optional(),
  modal: z
    .object({
      overlay: styleOptionSchema.optional(),
      container: styleOptionSchema.optional(),
      dialog: z
        .object({
          container: styleOptionSchema.optional(),
          title: z
            .object({
              container: styleOptionSchema.optional(),
              text: styleOptionSchema.optional(),
              closeIcon: styleOptionSchema.optional(),
            })
            .optional(),
          content: styleOptionSchema.optional(),
          newConversation: z
            .object({
              text: styleOptionSchema.optional(),
              button: styleOptionSchema.optional(),
            })
            .optional(),
        })
        .optional(),
    })
    .optional(),
  notification: z
    .object({
      container: styleOptionSchema.optional(),
      title: styleOptionSchema.optional(),
      description: styleOptionSchema.optional(),
      icon: styleOptionSchema.optional(),
      closeIcon: styleOptionSchema.optional(),
    })
    .optional(),
  header: z
    .object({
      container: styleOptionSchema.optional(),
      content: z.object({
        container: styleOptionSchema.optional(),
        title: styleOptionSchema.optional(),
        description: styleOptionSchema.optional(),
        avatar: avatarThemeSchema.optional(),
        actions: z
          .object({
            container: styleOptionSchema.optional(),
            icons: styleOptionSchema.optional(),
          })
          .optional(),
      }),
      expandedContent: z
        .object({
          container: styleOptionSchema.optional(),
          group: styleOptionSchema.optional(),
          descriptionItems: z
            .object({
              container: styleOptionSchema.optional(),
              icon: styleOptionSchema.optional(),
              text: styleOptionSchema.optional(),
              link: styleOptionSchema.optional(),
              poweredBy: styleOptionSchema.optional(),
            })
            .optional(),
        })
        .optional(),
      description: z
        .object({
          container: styleOptionSchema.optional(),
          title: styleOptionSchema.optional(),
          subtitle: styleOptionSchema.optional(),
        })
        .optional(),
      newConversationIcon: styleOptionSchema.optional(),
    })
    .optional(),
  composer: z
    .object({
      container: styleOptionSchema.optional(),
      input: styleOptionSchema.optional(),
      uploadButton: z
        .object({
          container: styleOptionSchema.optional(),
          icon: styleOptionSchema.optional(),
        })
        .optional(),
      button: z
        .object({
          container: styleOptionSchema.optional(),
          icon: styleOptionSchema.optional(),
        })
        .optional(),
    })
    .optional(),
  fab: z
    .object({
      icon: styleOptionSchema.optional(),
      container: styleOptionSchema.optional(),
    })
    .optional(),
  messageList: z
    .object({
      scrollbar: z
        .object({
          container: styleOptionSchema.optional(),
          viewport: styleOptionSchema.optional(),
          thumb: styleOptionSchema.optional(),
          corner: styleOptionSchema.optional(),
          background: styleOptionSchema.optional(),
        })
        .optional(),
      container: styleOptionSchema.optional(),
      marquee: z
        .object({
          content: styleOptionSchema.optional(),
          title: styleOptionSchema.optional(),
          description: styleOptionSchema.optional(),
          avatar: avatarThemeSchema.optional(),
          container: styleOptionSchema.optional(),
        })
        .optional(),
      scrollDownButton: z
        .object({
          container: styleOptionSchema.optional(),
          button: styleOptionSchema.optional(),
          icon: styleOptionSchema.optional(),
        })
        .optional(),
    })
    .optional(),
  message: z
    .object({
      container: styleOptionSchema.optional(),
      avatar: avatarThemeSchema.optional(),
      blocks: blockThemeSchema.optional(),
    })
    .optional(),
  loadingIndicator: z
    .object({
      container: styleOptionSchema.optional(),
      loader: styleOptionSchema.optional(),
    })
    .optional(),
})
