import * as Collapsible from '@radix-ui/react-collapsible'
import { FC, ReactNode, useState } from 'react'
import { StyleOptions } from '../../contexts'
import { Avatar } from '../Avatar'
import { RestartConversation } from '../RestartConversation'
import { CloseWindow } from '../CloseWindow'
import {
  DocumentTextIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  LockClosedIcon,
  PhoneIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/solid'
import { useWebchatStore } from '../../hooks'
import { webchatClasses } from '../../styles/classes'

type HeaderProps = {
  defaultOpen?: boolean
  open?: boolean
  disabled?: boolean
  onOpenChange?(open: boolean): void
}

type DescriptionItemProps = {
  title?: ReactNode
  icon?: FC<StyleOptions>
  link?: string
}
const DescriptionItem = ({ icon, title, link, ...props }: DescriptionItemProps) => {
  const { header } = webchatClasses

  const Icon = icon ?? ArrowTopRightOnSquareIcon

  if (!title) {
    return null
  }

  //TODO: This can be refactored to be more generic
  if (link) {
    return (
      <a
        {...props}
        {...header.expandedContent?.descriptionItems?.container}
        href={link}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Icon {...header.expandedContent?.descriptionItems?.icon} />
        <p {...header.expandedContent?.descriptionItems?.link}>{title}</p>
      </a>
    )
  }

  return (
    <div {...props} {...header.expandedContent?.descriptionItems?.container}>
      <Icon {...header.expandedContent?.descriptionItems?.icon} />
      <p {...header.expandedContent?.descriptionItems?.text}>{title}</p>
    </div>
  )
}
export const Header = ({ defaultOpen, disabled, onOpenChange, ...props }: HeaderProps) => {
  const { header } = webchatClasses
  const configuration = useWebchatStore((s) => s.configuration)
  const closeWindow = useWebchatStore((s) => s.closeWindow)
  const clientId = useWebchatStore((s) => s.getClientId)()

  const enableRestartConversation = useWebchatStore((s) => !s.disableRestartConversation)

  const [open, setOpen] = useState(!!defaultOpen)

  const expandedEnabled =
    !!configuration.email?.title ||
    !!configuration.phone?.title ||
    !!configuration.website?.title ||
    !!configuration.termsOfService?.link ||
    !!configuration.privacyPolicy?.link

  return (
    <Collapsible.Root
      open={expandedEnabled ? open : false}
      onOpenChange={setOpen}
      data-disabled={expandedEnabled ? undefined : ''}
      {...header.container}
      {...props}
    >
      <Collapsible.CollapsibleTrigger {...header.content.container}>
        <Avatar {...props} userId={clientId} src={configuration.botAvatar} {...header.content.avatar}>
          {configuration.botName || 'Bot'}
        </Avatar>
        <h2 {...header.content.title}>{configuration.botName || 'Bot'}</h2>
        {configuration.botDescription && <p {...header.content.description}>{configuration.botDescription}</p>}
        <div {...header.content.actions.container}>
          {enableRestartConversation && <RestartConversation />}
          {closeWindow && <CloseWindow />}
        </div>
      </Collapsible.CollapsibleTrigger>
      <Collapsible.Content {...header.expandedContent.container}>
        <div data-links="" {...header.expandedContent.group}>
          <DescriptionItem
            data-email=""
            icon={EnvelopeIcon}
            title={configuration.email?.title}
            link={
              configuration.email?.link?.startsWith('mailto:')
                ? configuration.email?.link
                : `mailto:${configuration.email?.link}`
            }
          />

          <DescriptionItem
            data-phone=""
            icon={PhoneIcon}
            title={configuration.phone?.title}
            link={
              configuration.phone?.link?.startsWith('tel:')
                ? configuration.phone?.link
                : `tel:${configuration.phone?.link}`
            }
          />

          <DescriptionItem
            data-website=""
            link={configuration.website?.link}
            icon={GlobeAltIcon}
            title={configuration.website?.title}
          />
        </div>

        <div data-legal="" {...header.expandedContent?.group}>
          <DescriptionItem
            data-terms=""
            link={configuration.termsOfService?.link}
            icon={DocumentTextIcon}
            title={configuration.termsOfService?.title}
          />
          <DescriptionItem
            data-privacy=""
            link={configuration.privacyPolicy?.link}
            icon={LockClosedIcon}
            title={configuration.privacyPolicy?.title}
          />
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  )
}
