import { forwardRef } from 'react'
import { FileBlock, type CommonBlockProps } from '../../../types'
import { DocumentArrowDownIcon } from '@heroicons/react/20/solid'

import { webchatClasses } from '../../../styles/classes'

type Props = FileBlock & CommonBlockProps
export const File = forwardRef<HTMLAnchorElement, Props>(({ type, title, url, ...props }, ref) => {
  const {
    message: {
      blocks: { file },
    },
  } = webchatClasses
  return (
    <a {...props} {...file.container} href={url} download ref={ref} target="_blank" rel="noreferrer">
      <p {...file.title}>{title || url}</p>
      <DocumentArrowDownIcon {...file.icon} />
    </a>
  )
})
