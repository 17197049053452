import { Theme } from './theme'
import { target } from '../adapters'

export type BlockStyles = NonNullable<Theme['message']>['blocks']

export type CommonBlockProps = Partial<
  Pick<MessageObject, 'direction' | 'timestamp' | 'sender'> & { messageId: string; metadata?: Record<string, any> }
>

export type AudioBlock = target.AudioMessage
export type BubbleBlock = target.BubbleMessage
export type ButtonBlock = target.ButtonMessage
export type CarouselBlock = target.CarouselMessage
export type ColumnBlock = target.ColumnMessage
export type DropdownBlock = target.DropdownMessage
export type FileBlock = target.FileMessage
export type ImageBlock = target.ImageMessage
export type LocationBlock = target.LocationMessage
export type RowBlock = target.RowMessage
export type TextBlock = target.TextMessage
export type VideoBlock = target.VideoMessage

export type BlockObject = target.Message
export type BlockType = BlockObject['type']

export type Sender = {
  name: string
  avatar?: string
}

export type MessageObject<T = BlockObject> = {
  id: string
  direction: 'incoming' | 'outgoing' | 'system'
  disableInput?: boolean
  sender: Sender
  timestamp: Date
  metadata?: Record<string, any>
  block: T
}

export type BlockObjects = {
  [T in BlockType]: Extract<BlockObject, { type: T }>
}

export const fileTypes = ['audio', 'file', 'image', 'video'] as const
export type FileType = (typeof fileTypes)[number]
export const isFileType = (type: string): type is FileType => {
  return fileTypes.includes(type as FileType)
}
