import dayjs, { OpUnitType } from 'dayjs'
import { MessageObject } from '../types'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import { v4 } from 'uuid'

dayjs.extend(isToday)
dayjs.extend(isYesterday)

/**
 * @param interval - The interval to check for date differences (default is 'day')
 * @returns Array of message objects with system date messages inserted at every change of date more than the `interval`
 *  */
export function withDateSystemMessages(messages: MessageObject[], interval: OpUnitType = 'day'): MessageObject[] {
  if (!messages || messages.length === 0) return []

  const result: MessageObject[] = []
  if (messages[0]?.timestamp) {
    result.push(getDateSystemMessage(messages[0].timestamp))
  }

  for (let i = 0; i < messages.length; i++) {
    if (messages[i]) {
      result.push(messages[i]!)
    }

    if (i + 1 < messages.length) {
      const currentDate = messages[i]?.timestamp
      const nextDate = messages[i + 1]?.timestamp

      if (!dayjs(currentDate).isSame(nextDate, interval) && nextDate) {
        result.push(getDateSystemMessage(nextDate))
      }
    }
  }

  return result
}

function getDateSystemMessage(date: Date): MessageObject {
  return {
    id: v4(),
    direction: 'system',
    sender: { name: 'system' },
    timestamp: date,
    block: {
      type: 'text',
      text: humanizeTimestamp(date),
    },
  }
}

export function humanizeTimestamp(timestamp: Date, long = false): string {
  const date = dayjs(timestamp)
  const timeFormat = 'h:mma'

  if (date.isToday()) {
    return long ? `Today • ${date.format(timeFormat)}` : 'Today'
  } else if (date.isYesterday()) {
    return long ? `Yesterday • ${date.format(timeFormat)}` : 'Yesterday'
  } else {
    return long ? date.format(`YYYY-MM-DD • ${timeFormat}`) : date.format('YYYY-MM-DD')
  }
}
