import { forwardRef, useId, useLayoutEffect, useState } from 'react'
import { ImageBlock, type CommonBlockProps } from '../../../types'
import { useMessageContext } from '../../../contexts'
import { getImageSize } from '../../../services'
import { webchatClasses } from '../../../styles/classes'
import { useAsync } from 'react-use'

type Props = ImageBlock & CommonBlockProps
export const Image = forwardRef<HTMLImageElement, Props>(({ url, type, orientation = 'auto', ...props }, ref) => {
  const id = useId()
  const { setIsLoading } = useMessageContext()
  const [imageOrientation, setImageOrientation] = useState(orientation)
  const [loaded, setLoaded] = useState(false)

  const {
    message: {
      blocks: { image },
    },
  } = webchatClasses

  useLayoutEffect(() => {
    setIsLoading((prev) => [...prev, id])
  }, [])

  useAsync(async () => {
    if (!url) return
    if (imageOrientation === 'auto') {
      try {
        const { width, height } = await getImageSize(url)
        setImageOrientation(getClosestAspectRatio(width, height))
      } catch (error) {
        setImageOrientation('square')
        console.error(error)
      }
      setIsLoading((prev) => {
        return prev.filter((currId) => currId !== id)
      })
    }
  }, [url])

  return (
    <>
      {loaded ? null : <div data-orientation={imageOrientation} {...image.placeholder} />}
      <img
        data-orientation={imageOrientation}
        data-loaded={loaded}
        {...props}
        {...image.image}
        src={url}
        alt=""
        ref={ref}
        loading="lazy"
        onLoad={() => {
          setLoaded(true)
        }}
      />
    </>
  )
})

function getClosestAspectRatio(width: number, height: number) {
  type aspects = keyof typeof aspects
  const aspects = {
    square: 1,
    portrait: 3 / 4,
    landscape: 4 / 3,
  } as const
  const aspect = width / height
  const closest = (Object.keys(aspects) as aspects[]).reduce((prev, curr) => {
    return Math.abs(aspects[curr] - aspect) < Math.abs(aspects[prev] - aspect) ? curr : prev
  })
  return closest
}
