import { forwardRef } from 'react'
import { VideoBlock, type CommonBlockProps } from '../../../types'
import { webchatClasses } from '../../../styles/classes'

type Props = VideoBlock & CommonBlockProps
export const Video = forwardRef<HTMLVideoElement, Props>(({ url, type, ...props }, ref) => {
  const {
    message: {
      blocks: { video },
    },
  } = webchatClasses
  return (
    <div>
      <video {...props} controls src={url} {...video} ref={ref} />
    </div>
  )
})
