import { CSSProperties, FC, createContext, useContext } from 'react'
import { Configuration, BlockObjects, BlockType } from '../types'

export type StyleOptions = {
  className?: string
  style?: CSSProperties
}

export type Renderers = {
  [T in BlockType]: FC<BlockObjects[T]>
}

export const WebchatConfigContext = createContext<Configuration | null>(null)

export function useWebchatConfig() {
  const context = useContext(WebchatConfigContext)
  if (!context) {
    throw new Error('useWebchatConfig must be used within a WebchatConfigProvider')
  }
  return context
}
