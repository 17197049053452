import { useEffect, useRef, useState, type ReactNode } from 'react'
import { useCreateWebchatStore, useOfflineStore, WebchatContext, type WebchatProps, type WebchatStore } from '../stores'
import { useWebchatStore } from '../hooks'
import { useAsync } from 'react-use'
import type { MessageObject } from '../types'
import '../styles/index.css'
import { backOff } from 'exponential-backoff'
import { WebchatConfigContext } from '../contexts'
import { UserCredentials } from '../client'
import { webchatToTarget } from '../adapters'
import deepEqual from 'deep-equal'

type WebchatProviderProps = Partial<React.PropsWithChildren<WebchatProps>>

export const WebchatProvider = ({ children, ...props }: WebchatProviderProps) => {
  const storeRef = useRef<WebchatStore>()
  const messageContainerRef = useRef<HTMLDivElement>(null)

  const store = useCreateWebchatStore({ ...props, messageContainerRef })
  if (!storeRef.current) {
    storeRef.current = store
  }

  return (
    <WebchatConfigContext.Provider value={{ ...props.configuration }}>
      <WebchatContext.Provider value={storeRef.current}>
        <Setup>{children}</Setup>
      </WebchatContext.Provider>
    </WebchatConfigContext.Provider>
  )
}

const Setup = ({ children }: { children: ReactNode }) => {
  const client = useWebchatStore((s) => s.client)
  const addMessage = useWebchatStore((s) => s.addMessage)
  const setMessages = useWebchatStore((s) => s.setMessages)
  const configuration = useWebchatStore((s) => s.configuration)
  const setConnected = useWebchatStore((s) => s.setConnected)
  const userData = useWebchatStore((s) => s.userData)
  const userName = useWebchatStore((s) => s.userName)
  const userPictureUrl = useWebchatStore((s) => s.userPictureUrl)
  const setIsTyping = useWebchatStore((s) => s.setIsTyping)
  const getMessages = useWebchatStore((s) => s.getMessages)
  const setHeaderMessage = useWebchatStore((s) => s.setHeaderMessage)
  const setDisableComposer = useWebchatStore((s) => s.setDisableComposer)

  const setConversationId = useOfflineStore((s) => s.setConversationId)
  const user = useOfflineStore((s) => s.user)
  const conversationId = useOfflineStore((s) => s.conversationId)
  const setUser = useOfflineStore((s) => s.setUser)

  const [counter, setCounter] = useState(0)

  //handles message sent directly by client
  useEffect(() => {
    if (!client) {
      return
    }

    return client.on('messageSent', (messageSent) => {
      const localMessages = getMessages()

      const lastLocalMessage = localMessages[localMessages.length - 1]

      if (
        localMessages.length === 0 ||
        !deepEqual(lastLocalMessage?.block, webchatToTarget.messageAdapter(messageSent).payload)
      ) {
        addMessage({
          direction: 'outgoing',
          sender: { name: 'You' },
          timestamp: new Date(),
          block: webchatToTarget.messageAdapter(messageSent).payload,
          disableInput: false,
        })
      }
    })
  }, [conversationId])

  useEffect(() => {
    if (!client) {
      return
    }
    return client.on('message', (message) => {
      try {
        const { payload, disableInput, conversationId: messageConversationId, sentOn, ...props } = message
        if (conversationId !== messageConversationId) {
          return
        }
        setIsTyping(false, 0)
        addMessage({
          ...props,
          direction: 'incoming',
          sender: { name: configuration.botName ?? 'Bot', avatar: configuration.botAvatar },
          timestamp: new Date(sentOn),
          block: payload,
          disableInput,
        })
      } catch (err) {
        console.error('Invalid message payload')
      }
    })
  }, [conversationId])

  useEffect(() => {
    if (!client) {
      return
    }
    return client.on('conversation', (conversationId) => {
      setConversationId(conversationId)
    })
  }, [])

  useAsync(async () => {
    if (!client) {
      return
    }
    return client.on('error', (err) => {
      console.error('Connection Error', err)
      // force re-render to re-connect and re-list messages
      setCounter((previousCount) => previousCount + 1)
    })
  })

  useAsync(async () => {
    if (!client) {
      return
    }

    if (conversationId && client.conversationId === conversationId) {
      return
    }

    let newUser: UserCredentials | undefined
    try {
      setHeaderMessage('Connecting...')
      setDisableComposer(true)
      newUser = await backOff(
        () =>
          client.connect(user, userData, {
            name: userName,
            pictureUrl: userPictureUrl,
          }),
        { maxDelay: 10000, numOfAttempts: Infinity }
      )
      setHeaderMessage(undefined)
      setDisableComposer(false)
      setConnected(true)
    } catch (thrown) {
      console.warn(thrown)
      setHeaderMessage('Connection failed. Please try again later.')
      setConnected(false)
      return
    }

    setUser(newUser)

    if (conversationId && (await client.conversationExists(conversationId))) {
      await client.switchConversation(conversationId)
    } else {
      // triggers the conversationStarted event and allows bot to send proactive messages
      await client.newConversation()
    }

    const parsedMessages: MessageObject[] = []

    for (const message of await client.listMessages()) {
      try {
        const { payload, authorId, sentOn, disableInput, ...props } = message
        const direction = authorId === client.userId ? 'outgoing' : 'incoming'

        parsedMessages.push({
          ...props,
          direction,
          sender:
            direction === 'outgoing'
              ? { name: 'You' }
              : { name: configuration.botName ?? 'Bot', avatar: configuration.botAvatar },
          timestamp: new Date(sentOn),
          block: payload,
          disableInput,
        })
      } catch (err) {
        console.error('Invalid message payload')
      }
    }

    setMessages(parsedMessages.reverse())

    return () => {
      void client.disconnect()
    }
  }, [counter, conversationId])

  return <>{children}</>
}
