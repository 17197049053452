import { Marquee } from '../Marquee'
import { ArrowDownIcon } from '@heroicons/react/20/solid'
import { ComponentProps, FC, memo, useCallback, useEffect, useMemo, useState, type ReactNode } from 'react'
import { useScroll } from 'react-use'
import { Message } from '../Message'
import { StyleOptions } from '../../contexts'
import { useWebchatStore } from '../../hooks'
import { withDateSystemMessages } from '../../utils'
import { TypingIndicator } from '../TypingIndicator'
import { webchatClasses } from '../../styles/classes'
import { useOfflineStore } from '../../stores/offlineStore'
import ScrollToBottom, { useScrollToBottom, useSticky } from 'react-scroll-to-bottom'
import clsx from 'clsx'

type MessageListProps = {
  scrollDownButton?: { text?: string; icon?: FC<StyleOptions> }
}
export const MessageList = memo(
  ({ scrollDownButton, className, ...props }: ComponentProps<'ul'> & MessageListProps) => {
    const { messageList } = webchatClasses
    const conversationId = useOfflineStore((s) => s.conversationId)
    const messages = useWebchatStore((s) => s.messages)[conversationId ?? ''] ?? []
    const setState = useWebchatStore((s) => s.setState)
    const client = useWebchatStore((s) => s.client)
    const containerRef = useWebchatStore((s) => s.messageContainerRef)
    const isTyping = useWebchatStore((s) => s.isTyping)
    const setIsTyping = useWebchatStore((s) => s.setIsTyping)
    const headerMessage = useWebchatStore((s) => s.headerMessage)

    const messagesWithDates = useMemo(() => withDateSystemMessages(messages), [messages])
    const [isAtBottom, setIsAtBottom] = useState(true)

    const { y } = useScroll(containerRef)

    const scroll = useCallback(() => {
      if (!containerRef.current) return
      const { scrollHeight } = containerRef.current
      if (isAtBottom) {
        containerRef.current.scrollTo({ top: scrollHeight })
      }
    }, [])

    useEffect(() => {
      return client?.on('isTyping', ({ isTyping: isBotTyping, timeout }) => {
        setIsTyping(isBotTyping, timeout)
      })
    }, [])

    useEffect(() => {
      const lastMessage = messages[messages.length - 1]
      setState({ disableComposer: !!lastMessage?.disableInput })
    }, [messages.length])

    useEffect(() => {
      if (!containerRef.current) return
      const { offsetHeight, scrollHeight, scrollTop } = containerRef.current
      setIsAtBottom(scrollHeight <= scrollTop + offsetHeight + 100)
    }, [y])

    const ScrollDownButtonIcon = scrollDownButton?.icon ? scrollDownButton?.icon : ArrowDownIcon

    return (
      <ScrollToBottom
        {...props}
        className={clsx(messageList.container.className, className)}
        followButtonClassName="bpHidden"
        scrollViewClassName={messageList.viewPort.className}
      >
        {headerMessage && <span {...messageList.headerMessage}>{headerMessage}</span>}
        <Marquee />
        {messagesWithDates.map((message) => (
          <Message scroll={scroll} key={message.id} {...message} />
        ))}
        {isTyping && (
          <Message scroll={scroll} direction={'incoming'}>
            <TypingIndicator />
          </Message>
        )}
        <li data-is-at-bottom={isAtBottom} {...messageList.scrollDownButton.container}>
          <ScrollToBottomButton>
            {scrollDownButton?.text ?? 'Back'} <ScrollDownButtonIcon {...messageList.scrollDownButton.icon} />
          </ScrollToBottomButton>
        </li>
      </ScrollToBottom>
    )
  }
)

type ScrollToBottomButtonProps = {
  className?: string
  children?: ReactNode
}
const ScrollToBottomButton = (props: ScrollToBottomButtonProps) => {
  const [sticky] = useSticky()
  const scrollToBottom = useScrollToBottom()
  const {
    messageList: { scrollDownButton },
  } = webchatClasses
  return !sticky && <button type="button" {...scrollDownButton.button} onClick={() => scrollToBottom()} {...props} />
}
