import { ReactNode, createContext, useContext } from 'react'

type ModalContextVaue = {
  open: boolean
  showModal: (props: { title: string; content: ReactNode }) => void
  hideModal: () => void
  title: string
  content: ReactNode | null
}

export const ModalContext = createContext<ModalContextVaue | null>(null)

export function useModalContext() {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider')
  }
  return context
}
