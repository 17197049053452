import { AvatarTheme } from '../types'
import { AvatarFallback, AvatarImage, Avatar as RadixAvatar } from '@radix-ui/react-avatar'
import { ComponentPropsWithRef, forwardRef } from 'react'
import { hashStringToInt } from '../utils'

type AvatarProps = {
  userId?: string
  src?: string
} & Omit<ComponentPropsWithRef<'span'>, 'className' | 'style'> &
  AvatarTheme

export const Avatar = forwardRef<HTMLSpanElement, AvatarProps>(
  ({ userId, src, container, image, fallback, ...props }, ref) => {
    const userIdHash = hashStringToInt(userId ?? '', 15)
    return (
      <RadixAvatar ref={ref} data-color={userIdHash} {...container} {...props}>
        <AvatarImage src={src} {...image} />
        <AvatarFallback {...fallback}>
          {typeof props.children === 'string' ? props.children[0] : props.children}
        </AvatarFallback>
      </RadixAvatar>
    )
  }
)
