import { forwardRef, memo, useState, type ReactNode } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from '@radix-ui/react-avatar'
import { MessageContext } from '../../contexts'
import type { MessageObject } from '../../types'
import { Block } from '../Block'
import { hashStringToInt } from '../../utils'
import { useWebchatStore } from '../../hooks'
import { webchatClasses } from '../../styles/classes'

type MessageProps = {
  scroll: () => void
  children?: ReactNode
} & MessageObject
export const Message = memo(
  forwardRef<HTMLDivElement, Partial<MessageProps>>(
    ({ direction, block, scroll, disableInput, children, sender, id, metadata, ...props }, ref) => {
      const [isLoading, setIsLoading] = useState<string[]>([])
      const { message: styles } = webchatClasses
      const { botAvatar, botName } = useWebchatStore((s) => s.configuration)
      const clientId = useWebchatStore((s) => s.getClientId)()

      const userIdHash = hashStringToInt(clientId ?? '', 15)

      return (
        <MessageContext.Provider value={{ isLoading, setIsLoading }}>
          <div
            {...props}
            {...styles?.container}
            data-loaded={isLoading.length === 0}
            data-disable-input={!!disableInput}
            data-direction={direction}
            ref={ref}
          >
            <Avatar data-color={userIdHash} {...styles?.avatar?.container}>
              <AvatarImage {...styles?.avatar?.image} src={sender?.avatar ?? botAvatar} />
              <AvatarFallback {...styles?.avatar?.fallback}>
                {sender?.name[0] ?? (botName ?? 'Bot')?.[0]}
              </AvatarFallback>
            </Avatar>
            {block && <Block {...{ block, direction, sender, timestamp: props.timestamp, messageId: id, metadata }} />}
            {children}
          </div>
        </MessageContext.Provider>
      )
    }
  )
)
