import { useWebchatStore } from '../../hooks'
import { webchatClasses } from '../../styles/classes'
import { Avatar } from '../Avatar'

export function Marquee() {
  const clientId = useWebchatStore((s) => s.getClientId)()
  const configuration = useWebchatStore((s) => s.configuration)

  const {
    messageList: { marquee },
  } = webchatClasses

  return (
    <li {...marquee.container}>
      <Avatar userId={clientId} src={configuration.botAvatar} {...marquee.avatar}>
        {(configuration.botName || 'Bot').slice(0, 1)}
      </Avatar>
      <div {...marquee.content}>
        <h1 {...marquee.title}>{configuration.botName || 'Bot'}</h1>
        <p {...marquee.description}>{configuration.botDescription}</p>
      </div>
    </li>
  )
}
