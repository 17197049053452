import { Container } from './Container/Container'
import { Header } from './Header'
import { MessageList } from './MessageList/MessageList'
import { Composer } from './Composer/Composer'
import { useMemo } from 'react'
import { generateThemeStylesheet } from '../utils'
import { useWebchatConfig } from '../contexts'

export const Webchat = () => {
  const { color, fontFamily, radius, themeMode, variant } = useWebchatConfig()

  const cssString = useMemo(
    () => generateThemeStylesheet({ color, fontFamily, radius, themeMode, variant }),
    [color, fontFamily, radius, themeMode, variant]
  )

  return (
    <>
      <Container>
        <Header />
        <MessageList />
        <Composer />
      </Container>
      <style>{cssString}</style>
    </>
  )
}
