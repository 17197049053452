import { ComponentProps } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useWebchatStore } from '../hooks'
import { webchatClasses } from '../styles/classes'

type Props = ComponentProps<typeof XMarkIcon>

export const CloseWindow = ({ ...props }: Props) => {
  const { header } = webchatClasses
  const closeWindow = useWebchatStore((s) => s.closeWindow)

  const onSelect = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    closeWindow?.()
  }
  return (
    <XMarkIcon
      {...props}
      {...header.content.actions.icons}
      role="button"
      tabIndex={0}
      onClick={onSelect}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelect(e)
        }
      }}
    />
  )
}
