import { Dispatch, SetStateAction, createContext, useContext } from 'react'

type MessageContextVaue = {
  isLoading: string[]
  setIsLoading: Dispatch<SetStateAction<string[]>>
}

export const MessageContext = createContext<MessageContextVaue | null>(null)

export function useMessageContext() {
  const context = useContext(MessageContext)
  if (!context) {
    throw new Error('useMessageContext must be used within a Message')
  }
  return context
}
