import { ClientReturn } from 'botpress-client'

type BillingPlanFeatures = {
  name: string
  description: string
  inclusions: string[]
  exclusions: string[]
}

//TODO: This should be centralised. We need to keep this in sync with our website and stripe products
export const billingPlan: Record<ClientReturn<'getWorkspace'>['plan'], BillingPlanFeatures> = {
  community: {
    name: 'Pay-as-you-go',
    description: 'Start for free. Every month we give you a $5 credit to use towards AI Spend.',
    inclusions: [
      '5 bots',
      '2,000 incoming messages & events / month',
      '3 collaborators',
      '5,000 Table rows',
      '100MB vector database storage',
      '500MB File storage',
      '30 days of data retention',
      '100 API Req/sec',
      'Community support',
      '$5 AI Credit',
    ],
    exclusions: ['Role-based access control', 'Premium Web Crawler', 'Custom SSO', 'Multiple Environments'],
  },
  team: {
    name: 'Team',
    description: 'For teams with mature chatbot projects looking to save on limit increases.',
    inclusions: [
      '20 bots',
      '250,000 incoming messages & events / month',
      '5 collaborators',
      '1,000,000 Table rows',
      '2GB vector database storage',
      '10GB File storage',
      '90 days of data retention',
      '1000 API Req/sec',
      'Live-chat support',
    ],
    exclusions: ['Custom SSO', 'Multiple Environments', 'Dedicated support'],
  },
  enterprise: {
    name: 'Enterprise',
    description: 'For businesses requiring advanced features and enterprise security.',
    inclusions: [
      'Custom workspace limits',
      'Volume discounts',
      'Custom payment terms',
      'Custom SLAs & Agreements',
      'Dedicated support',
    ],
    exclusions: [],
  },
}
