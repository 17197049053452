import { Text } from './Text/Text'
import { Button } from './Button'
import { Bubble } from './Bubble'
import { Renderers } from '../../contexts'
import { Image } from './Image'
import { Video } from './Video'
import { Audio } from './Audio'
import { Location } from './Location'
import { Column } from './Column'
import { Row } from './Row'
import { Carousel } from './Carousel'
import { Dropdown } from './Dropdown'
import { File } from './File'

export const renderers: Renderers = {
  audio: Audio,
  bubble: Bubble,
  button: Button,
  carousel: Carousel,
  column: Column,
  dropdown: Dropdown,
  file: File,
  image: Image,
  location: Location,
  row: Row,
  text: Text,
  video: Video,
}
