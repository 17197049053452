import { JSX } from 'react'
import { Renderers } from '../contexts'
import { renderers as defaultRenderers } from './renderers'
import { BlockObjects, BlockType, type CommonBlockProps } from '../types'
import { useWebchatStore } from '../hooks'

type BlockProps<T extends BlockType> = { block: BlockObjects[T] } & Partial<CommonBlockProps>
export const Block = <T extends BlockType>({ block, ...props }: BlockProps<T>): JSX.Element => {
  type Renderer = Renderers[T]
  type RendererProps = BlockObjects[T]
  type ReactProps = JSX.IntrinsicAttributes & JSX.LibraryManagedAttributes<Renderer, RendererProps> & CommonBlockProps

  const renderers = useWebchatStore((s) => s.renderers)
  const BlockRenderer: Renderer = renderers[block.type] ?? defaultRenderers[block.type]
  const rendererProps = { ...props, ...block } as unknown as ReactProps

  return <BlockRenderer {...rendererProps} />
}
