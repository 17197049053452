import { ComponentProps, forwardRef } from 'react'
import { webchatClasses } from '../../styles/classes'

export const Fab = forwardRef<HTMLDivElement, ComponentProps<'div'>>(({ children, onClick, ...props }, ref) => {
  const { fab } = webchatClasses

  const { container, icon } = fab ?? {}

  return (
    <div {...props} {...container} ref={ref} onClick={onClick}>
      <div {...icon} />
    </div>
  )
})
