export const webchatClasses = {
  container: {
    className: 'bpReset bpContainer',
  },
  modal: {
    container: {
      className: 'bpReset bpModalContainer',
    },
    overlay: {
      className: 'bpModalOverlay',
    },
    dialog: {
      container: {
        className: 'bpReset bpModalDialogContainer',
      },
      title: {
        container: {
          className: 'bpReset bpModalDialogTitleContainer',
        },
        text: {
          className: 'bpModalDialogTitleText',
        },
        closeIcon: {
          className: 'bpModalDialogTitleCloseIcon',
        },
      },
      content: {
        className: 'bpModalDialogContent',
      },
      newConversation: {
        button: {
          className: 'bpModalDialogNewConversationButton',
        },
        text: {
          className: 'bpModalDialogNewConversationText',
        },
      },
    },
  },
  fab: {
    container: {
      className: 'bpReset bpFabContainer',
    },
    icon: {
      className: 'bpFabIcon',
    },
  },
  notification: {
    container: {
      className: 'bpReset bpNotificationContainer',
    },
    title: {
      className: 'bpNotificationTitle',
    },
    description: {
      className: 'bpNotificationDescription',
    },
    icon: {
      className: 'bpNotificationIcon',
    },
    closeIcon: {
      className: 'bpNotificationCloseIcon',
    },
  },
  header: {
    container: {
      className: 'bpReset bpHeaderContainer',
    },
    content: {
      container: {
        className: 'bpReset bpHeaderContentContainer',
      },
      title: {
        className: 'bpHeaderContentTitle',
      },
      description: {
        className: 'bpHeaderContentDescription',
      },
      avatar: {
        container: {
          className: 'bpReset bpHeaderContentAvatarContainer',
        },
        image: {
          className: 'bpHeaderContentAvatarImage',
        },
        fallback: {
          className: 'bpHeaderContentAvatarFallback',
        },
      },
      actions: {
        container: {
          className: 'bpReset bpHeaderContentActionsContainer',
        },
        icons: {
          className: 'bpHeaderContentActionsIcons',
        },
      },
    },
    expandedContent: {
      container: {
        className: 'bpReset bpHeaderExpandedContentContainer',
      },
      descriptionItems: {
        container: {
          className: 'bpReset bpHeaderExpandedContentDescriptionItemsContainer',
        },

        icon: {
          className: 'bpHeaderExpandedContentDescriptionItemsIcon',
        },
        text: {
          className: 'bpHeaderExpandedContentDescriptionItemsText',
        },
        link: {
          className: 'bpHeaderExpandedContentDescriptionItemsLink',
        },
      },
      group: {
        className: 'bpHeaderExpandedContentGroup',
      },
    },
    newConversationIcon: {
      className: 'bpHeaderNewConversationIcon',
    },
  },
  composer: {
    container: {
      className: 'bpReset bpComposerContainer',
    },
    poweredBy: {
      className: 'bpComposerPoweredBy',
    },
    input: {
      className: 'bpComposerInput',
    },
    button: {
      container: {
        className: 'bpReset bpComposerButtonContainer',
      },
      icon: {
        className: 'bpComposerButtonIcon',
      },
    },
    uploadButton: {
      icon: {
        className: 'bpComposerUploadButtonIcon',
      },
      container: {
        className: 'bpReset bpComposerUploadButtonContainer',
      },
    },
    voiceButton: {
      icon: {
        className: 'bpComposerVoiceButtonIcon',
      },
      container: {
        className: 'bpReset bpComposerVoiceButtonContainer',
      },
    },
  },
  messageList: {
    scrollDownButton: {
      container: {
        className: 'bpReset bpMessageListScrollDownButtonContainer',
      },
      button: {
        className: 'bpMessageListScrollDownButtonButton',
      },
      icon: {
        className: 'bpMessageListScrollDownButtonIcon',
      },
    },
    container: {
      className: 'bpReset bpMessageListContainer',
    },
    viewPort: {
      className: 'bpMessageListViewport',
    },
    marquee: {
      container: {
        className: 'bpReset bpMessageListMarqueeContainer',
      },
      content: {
        className: 'bpMessageListMarqueeContent',
      },
      title: {
        className: 'bpMessageListMarqueeTitle',
      },
      description: {
        className: 'bpMessageListMarqueeDescription',
      },
      avatar: {
        container: {
          className: 'bpReset bpMessageListMarqueeAvatarContainer',
        },
        image: {
          className: 'bpMessageListMarqueeAvatarImage',
        },
        fallback: {
          className: 'bpMessageListMarqueeAvatarFallback',
        },
      },
    },
    headerMessage: {
      className: 'bpMessageListHeaderMessage',
    },
  },
  message: {
    container: {
      className: 'bpReset bpMessageContainer',
    },
    avatar: {
      container: {
        className: 'bpReset bpMessageAvatarContainer',
      },
      image: {
        className: 'bpMessageAvatarImage',
      },
      fallback: {
        className: 'bpMessageAvatarFallback',
      },
    },
    blocks: {
      text: {
        heading1: {
          className: 'bpMessageBlocksTextHeading1',
        },
        heading2: {
          className: 'bpMessageBlocksTextHeading2',
        },
        heading3: {
          className: 'bpMessageBlocksTextHeading3',
        },
        unorderedList: {
          className: 'bpMessageBlocksTextUnorderedList',
        },
        orderedList: {
          className: 'bpMessageBlocksTextOrderedList',
        },
        link: {
          className: 'bpMessageBlocksTextLink',
        },
        italic: {
          className: 'bpMessageBlocksTextItalic',
        },
        bold: {
          className: 'bpMessageBlocksTextBold',
        },
        text: {
          className: 'bpMessageBlocksTextText',
        },
        horizontalRule: {
          className: 'bpMessageBlocksTextHorizontalRule',
        },
        listItem: {
          className: 'bpMessageBlocksTextListItem',
        },
        lineBreak: {
          className: 'bpMessageBlocksTextLineBreak',
        },
        pre: {
          className: 'bpMessageBlocksTextPre',
        },
      },
      audio: {
        className: 'bpMessageBlocksAudio',
      },
      image: {
        image: {
          className: 'bpMessageBlocksImageImage',
        },
        placeholder: {
          className: 'bpMessageBlocksImagePlaceholder',
        },
      },
      video: {
        className: 'bpMessageBlocksVideo',
      },
      location: {
        container: {
          className: 'bpMessageBlocksLocationContainer',
        },
        title: {
          className: 'bpMessageBlocksLocationTitle',
        },
        icon: {
          className: 'bpMessageBlocksLocationIcon',
        },
      },
      file: {
        container: {
          className: 'bpMessageBlocksFileContainer',
        },
        title: {
          className: 'bpMessageBlocksFileTitle',
        },
        icon: {
          className: 'bpMessageBlocksFileIcon',
        },
      },
      row: {
        className: 'bpMessageBlocksRow',
      },
      column: {
        className: 'bpMessageBlocksColumn',
      },
      bubble: {
        className: 'bpMessageBlocksBubble',
      },
      carousel: {
        container: {
          className: 'bpMessageBlocksCarouselContainer',
        },
        slidesContainer: {
          className: 'bpMessageBlocksCarouselSlidesContainer',
        },
        backButton: {
          className: 'bpMessageBlocksCarouselBackButton',
        },
        nextButton: {
          className: 'bpMessageBlocksCarouselNextButton',
        },
      },
      dropdown: {
        button: {
          container: {
            className: 'bpMessageBlocksDropdownButtonContainer',
          },
          text: {
            className: 'bpMessageBlocksDropdownButtonText',
          },
          icon: {
            className: 'bpMessageBlocksDropdownButtonIcon',
          },
        },
        content: {
          container: {
            className: 'bpMessageBlocksDropdownContentContainer',
          },
          item: {
            className: 'bpMessageBlocksDropdownContentItem',
          },
        },
      },
      button: {
        className: 'bpMessageBlocksButton',
      },
    },
  },
  typingIndicator: {
    container: {
      className: 'bpTypingIndicatorContainer',
    },
    loader: {
      className: 'bpTypingIndicatorLoader',
    },
  },
} as const
