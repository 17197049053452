import { PropsWithChildren, ReactNode, useState } from 'react'
import { Modal } from '../components'
import { ModalContext } from '../contexts'

export type ModalProps = {
  title: string | null
  content: ReactNode | null
}

type Props = PropsWithChildren

export const ModalProvider = ({ children }: Props) => {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState<string>('')
  const [content, setContent] = useState<ReactNode | null>(null)

  const showModal = ({ title, content }: { title: string; content: ReactNode }) => {
    setTitle(title)
    setContent(content)
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }

  return (
    <ModalContext.Provider value={{ hideModal, showModal, title, content, open }}>
      <Modal open={open} onOpenChange={setOpen}>
        <Modal.Content title={title}>{content}</Modal.Content>
      </Modal>
      {children}
    </ModalContext.Provider>
  )
}
