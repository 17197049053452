import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { UserCredentials } from '../client'

type OfflineStore = {
  messageHistory: {
    [key: string]: string[]
  }
  user?: UserCredentials
  conversationId?: string
  clientMode: string
  addMessageToHistory: (props: { userId: string; message: string }) => void
  setClientMode: (clientMode: string) => void
  setUser: (userId?: UserCredentials) => void
  setConversationId: (conversationId?: string) => void
  clearAll: () => void
}

export const useOfflineStore = create<OfflineStore>()(
  devtools(
    persist(
      (set, get) => ({
        messageHistory: {},
        addMessageToHistory: ({ userId, message }) =>
          set((state) => ({
            ...state,
            messageHistory: {
              ...state.messageHistory,
              [userId]: [message, ...(state.messageHistory[userId] ?? []).slice(0, 100)],
            },
          })),
        clientMode: '',
        setClientMode: (clientMode) => {
          const { clearAll, clientMode: prevClientMode } = get()
          if (prevClientMode !== clientMode) {
            clearAll()
          }
          set((state) => ({
            ...state,
            clientMode,
          }))
        },
        setUser: (user) =>
          set((state) => ({
            ...state,
            user,
          })),
        setConversationId: (conversationId) =>
          set((state) => ({
            ...state,
            conversationId,
          })),
        clearAll: () => {
          set((state) => ({
            ...state,
            messageHistory: {},
            user: undefined,
            conversationId: undefined,
          }))
        },
      }),
      {
        name: 'botpress-webchat',
      }
    )
  )
)
