import { ComponentProps, forwardRef, memo, useEffect, useRef, useState, type ChangeEvent, type Ref } from 'react'
import { ArrowUpIcon, PlusIcon, MicrophoneIcon } from '@heroicons/react/16/solid'
import { useWebchatStore } from '../../hooks'
import { useOfflineStore } from '../../stores'
import { webchatClasses } from '../../styles/classes'
import { useWebchatConfig } from '../../contexts'
import TextareaAutosize from 'react-textarea-autosize'
import clsx from 'clsx'

type Props = ComponentProps<'div'> & {
  inputRef?: Ref<HTMLTextAreaElement>
}
export const Composer = memo(
  forwardRef<HTMLDivElement, Props>(({ children, className, inputRef, ...props }, ref) => {
    const { composer } = webchatClasses

    const disableComposer = useWebchatStore((s) => s.disableComposer)
    const setIsTyping = useWebchatStore((s) => s.setIsTyping)
    const isReadOnly = useWebchatStore((s) => s.isReadOnly)
    const sendFile = useWebchatStore((s) => s.sendFile)
    const addMessage = useWebchatStore((s) => s.addMessage)
    const sendMessage = useWebchatStore((s) => s.sendTextMessage)
    const allowFileUpload = useWebchatStore((s) => s.allowFileUpload)
    const connected = useWebchatStore((s) => s.connected)
    const { composerPlaceholder, showPoweredBy } = useWebchatConfig()

    const [value, setValue] = useState('')
    const [historyIndex, setHistoryIndex] = useState(-1)
    const [isListening, setIsListening] = useState(false)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const recognitionRef = useRef<any | null>(null)

    const currentUser = useOfflineStore((state) => state.user)
    const messageHistory = useOfflineStore((state) => state.messageHistory)

    if (isReadOnly) {
      return null
    }

    const handleUploadFileClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current?.click()
      }
    }

    const composerDisabled = disableComposer || isReadOnly || !connected

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) {
        try {
          const { fileUrl, type } = (await sendFile(file)) ?? ''
          addMessage({
            direction: 'outgoing',
            sender: { name: 'You' },
            timestamp: new Date(),
            disableInput: false,
            block: { type, url: fileUrl },
          })
        } catch (error) {
          console.error('Error sending file:', error)
        }
      }
    }

    const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition

    const handleSpeechRecognition = (): void => {
      if (!recognitionRef.current) {
        recognitionRef.current = new SpeechRecognition()
      }

      const recognition = recognitionRef.current

      if (!recognition) return

      recognition.continuous = true

      recognition.onresult = (event: any) => {
        const transcript = event.results?.[0]?.[0]?.transcript ?? ''
        setValue(transcript ?? '')
        recognition.stop()
        setIsListening(false)
      }

      if (isListening) {
        recognition.stop()
        setIsListening(false)
      } else {
        recognition.start()
        setIsListening(true)
      }
    }

    const sendComposerMessage = () => {
      if (!value) return
      void sendMessage(value)
      setTimeout(() => setIsTyping(true, 9000), 500)
      setValue('')
      setHistoryIndex(-1)
    }

    return (
      <>
        <div
          data-disabled={composerDisabled}
          {...props}
          className={clsx(composer.container.className, className)}
          ref={ref}
        >
          {allowFileUpload && (
            <>
              <button
                type="button"
                disabled={composerDisabled}
                {...composer.uploadButton.container}
                onClick={handleUploadFileClick}
              >
                <PlusIcon {...composer.uploadButton.icon} />
              </button>
              <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
            </>
          )}
          <TextareaAutosize
            {...composer?.input}
            ref={inputRef}
            placeholder={composerPlaceholder ?? 'Type your message...'}
            disabled={composerDisabled}
            value={value}
            data-has-value={!!value}
            onChange={(e) => setValue(e.target.value)}
            maxRows={5}
            onKeyDown={(e) => {
              if (composerDisabled) return
              if (e.key === 'Enter' && e.shiftKey) {
                e.preventDefault()
                setValue(`${value}\n`)
              } else if (e.key === 'Enter') {
                e.preventDefault()
                sendComposerMessage()
              }
              if (e.key === 'ArrowUp') {
                e.preventDefault()
                const userHistory = messageHistory[currentUser?.userId ?? ''] ?? []
                if (historyIndex < userHistory.length - 1) {
                  setValue(userHistory[historyIndex + 1] ?? '')
                }
                setHistoryIndex(() => Math.min(historyIndex + 1, userHistory.length - 1))
              }
              if (e.key === 'ArrowDown') {
                e.preventDefault()
                const userHistory = messageHistory[currentUser?.userId ?? ''] ?? []
                if (historyIndex === 0) {
                  setValue('')
                } else {
                  setValue(userHistory[historyIndex - 1] ?? '')
                }
                setHistoryIndex(() => Math.max(historyIndex - 1, -1))
              }
            }}
          />

          {value && (
            <button
              type="button"
              aria-label="Send message"
              {...composer.button.container}
              disabled={!value || composerDisabled}
              onClick={sendComposerMessage}
            >
              <ArrowUpIcon {...composer.button.icon} />
            </button>
          )}

          {!value && SpeechRecognition && (
            <button
              type="button"
              aria-label="Activate voice input"
              {...composer.voiceButton.container}
              disabled={!!value}
              onClick={handleSpeechRecognition}
              data-listens={!!isListening}
            >
              <MicrophoneIcon {...composer.voiceButton.icon} />
            </button>
          )}
        </div>
        <a
          {...composer.poweredBy}
          data-hidden={showPoweredBy === false}
          hidden={showPoweredBy === false}
          href="https://botpress.com/?from=webchat"
          target="_blank"
          rel="noopener noreferrer"
        >
          ⚡ by Botpress
        </a>
      </>
    )
  })
)
