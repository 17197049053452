type CallbackFn<T> = (args: T) => void

export class EventEmitter<T extends Record<string, any>> {
  private readonly eventMap: Partial<Record<keyof T, Set<CallbackFn<any>>>>

  constructor() {
    this.eventMap = {}
  }

  on<U extends keyof T>(event: U, callback: CallbackFn<T[U]>): () => void {
    if (!this.eventMap[event]) {
      this.eventMap[event] = new Set<CallbackFn<T[U]>>()
    }
    this.eventMap[event]?.add(callback)

    return () => {
      this.eventMap[event]?.delete(callback)
    }
  }

  emit<U extends keyof T>(event: U, arg?: T[U]): void {
    this.eventMap[event]?.forEach((callback) => {
      callback(arg)
    })

    this.eventMap['*']?.forEach((callback) => {
      callback({ type: event, payload: arg })
    })
  }
}

export type Events = {
  [key: string]: undefined
}

export const eventEmitter = new EventEmitter<Events>()
