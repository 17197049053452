import { z } from 'zod'
import { configurationSchema } from './configuration'
import { themeSchema } from './theme'

export const statePropsSchema = z.enum(['opened', 'closed', 'initial'])

export const userPropsSchema = z.object({
  name: z.string().optional(),
  pictureUrl: z.string().optional(),
  data: z.record(z.unknown()).optional(),
})

export const configPropsSchema = z.object({
  configuration: configurationSchema.optional().catch(undefined),
  theme: themeSchema.optional().catch(undefined),
  style: z.string().optional().catch(undefined),
  user: userPropsSchema.optional().catch(undefined),
})

export const initPropsSchema = configPropsSchema.extend({
  clientId: z.string(),
  botId: z.string(),
  selector: z.string().optional().catch(undefined),
  webchatScriptUrl: z.string().optional().catch(undefined),
  fabScriptUrl: z.string().optional().catch(undefined),
  pushpinUrl: z.string().optional().catch(undefined),
  defaultState: statePropsSchema.optional().catch(undefined),
})
