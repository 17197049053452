import { forwardRef, useCallback, useEffect, useState } from 'react'
import { CarouselBlock, type CommonBlockProps } from '../../../types'
import useEmblaCarousel, { EmblaCarouselType } from 'embla-carousel-react'
import { Block } from '../../Block'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/solid'
import { webchatClasses } from '../../../styles/classes'

type Props = CarouselBlock & CommonBlockProps
export const Carousel = forwardRef<HTMLDivElement, Props>(({ blocks, direction, sender, timestamp, metadata }, ref) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ skipSnaps: true, loop: true })

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi])
  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi])

  const {
    message: {
      blocks: { carousel: carouselStyles },
    },
  } = webchatClasses

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return
    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  return (
    <div {...carouselStyles?.container} data-container="carousel" ref={emblaRef}>
      <div ref={ref} {...carouselStyles?.slidesContainer}>
        {blocks.map((block, index) => (
          <Block key={index} {...{ block, direction, sender, timestamp, metadata }} />
        ))}
      </div>
      {blocks.length > 1 && (
        <>
          <ChevronLeftIcon
            {...carouselStyles?.backButton}
            data-disabled={prevBtnDisabled ? '' : undefined}
            onClick={scrollPrev}
          />

          <ChevronRightIcon
            {...carouselStyles?.nextButton}
            data-disabled={nextBtnDisabled ? '' : undefined}
            onClick={scrollNext}
          />
        </>
      )}
    </div>
  )
})
