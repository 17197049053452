import { Box, Flex, Grid, Popover, Separator, Text } from '@radix-ui/themes'
import { createFileRoute } from '@tanstack/react-router'
import { Card, DataListItem, DataListRoot, DropdownMenu, EmptyState, Icon, JSONTree } from '~/elementsv2'
import { Boundary, QueryParamBuilder, type FilterMenuInput, Page } from '~/componentsV2'
import { Fragment, useState } from 'react'
import { z } from 'zod'
import type { ClientReturn } from 'botpress-client'
import { DateTime } from 'luxon'
import EmptyStateIcon from '~/assets/programming-04.svg?react'
import { useSuspenseQuery } from '~/services'
import {
  HiEllipsisHorizontal,
  HiMiniChevronDown,
  HiMiniChevronUp,
  HiMiniChevronUpDown,
  HiOutlineMagnifyingGlass,
} from 'react-icons/hi2'
import { FileIcon, FileStatusBadge } from '~/features/files/components'
import { cn, formatters } from '~/utils'
import { Button, IconButton } from '@botpress/ui-kit'
import { getCdmStudioUrl } from '~/shared'

const filesSearchSchema = z.object({
  tags: z
    .record(z.string())
    .optional()
    .catch(() => undefined),
})

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/files')({
  validateSearch: filesSearchSchema,
  component: Component,
})

function Component() {
  return (
    <Boundary height={'200px'} loaderSize="6">
      <ConversationsPage />
    </Boundary>
  )
}

const ConversationsPage = () => {
  const filters: FilterMenuInput[] = [
    {
      name: 'Source',
      type: 'object',
      paramName: 'tags',
      value: [
        { name: 'Integration', paramName: 'tags:source', value: 'integration' },
        { name: 'Knowledge Base', paramName: 'tags:source', value: 'knowledge-base' },
      ],
    },
    { name: 'Title', type: 'object', paramName: 'tags:title' },
    { name: 'Knowledge Base Id', type: 'object', paramName: 'tags:kbId' },
    { name: 'Data Source Id', type: 'object', paramName: 'tags:dsId' },
    {
      name: 'System',
      type: 'object',
      paramName: 'tags:system',
      value: 'true',
    },
  ]

  return (
    <Page title="Files">
      <Flex direction={'column'} gap={'4'}>
        <QueryParamBuilder filters={filters} />
        <Boundary loaderSize={'6'}>
          <FilesList />
        </Boundary>
      </Flex>
    </Page>
  )
}

const FilesList = () => {
  const { workspaceId, botId } = Route.useParams()
  const { tags } = Route.useSearch()
  const [filters, setFilters] = useState({ orderBy: 'name', direction: 'asc' })

  const { orderBy, direction } = filters
  const files = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_/files/$tags/all', {
    botId,
    workspaceId,
    tags: tags ?? {},
  }).data.sort((_a, _b) => {
    const a = direction === 'asc' ? _a : _b
    const b = direction === 'asc' ? _b : _a
    if (orderBy === 'name') {
      return a.key.localeCompare(b.key)
    }
    if (orderBy === 'size') {
      return (a.size ?? 0) - (b.size ?? 0)
    }
    if (orderBy === 'updatedAt') {
      return DateTime.fromISO(a.updatedAt).toMillis() - DateTime.fromISO(b.updatedAt).toMillis()
    }
    if (orderBy === 'status') {
      return a.status.localeCompare(b.status)
    }
    return 0
  })

  return (
    <>
      <Card className="p-0">
        <Text asChild size={'2'}>
          <Grid gapX={'7'} className="relative grid-cols-[auto,auto,1fr,1fr,1fr,auto,auto]">
            <Grid align={'center'} p={'2'} className="col-[1/-1] grid-cols-subgrid bg-gray-2 font-medium">
              <div />
              <ColumnFilterButton filters={filters} setFilters={setFilters} field="name">
                Name
              </ColumnFilterButton>
              <ColumnFilterButton filters={filters} setFilters={setFilters} field="size">
                Size
              </ColumnFilterButton>
              <ColumnFilterButton filters={filters} setFilters={setFilters} field="updatedAt">
                Last Modified
              </ColumnFilterButton>
              <Text>Tags</Text>
              <ColumnFilterButton filters={filters} setFilters={setFilters} field="status">
                Status
              </ColumnFilterButton>

              <div />
            </Grid>
            {files.length === 0 ? (
              <>
                <Separator size={'4'} className="col-[1/-1]" />
                <EmptyState
                  iconSize={6}
                  icon={EmptyStateIcon}
                  className="col-[1/-1] py-16"
                  title="You don't have any files!"
                  description="Files uploaded to this bot will appear here."
                  primaryAction={
                    <Button onClick={() => window.open(getCdmStudioUrl(botId), '_blank')}>Edit in Studio</Button>
                  }
                />
              </>
            ) : (
              files.map((file, i) => (
                <Fragment key={i}>
                  <Separator size={'4'} className="col-[1/-1]" />
                  <FileRow file={file} />
                </Fragment>
              ))
            )}
          </Grid>
        </Text>
      </Card>
    </>
  )
}

const ColumnFilterButton = ({
  children,
  field,
  filters: { orderBy, direction },
  setFilters,
}: {
  children: React.ReactNode
  field: 'name' | 'size' | 'updatedAt' | 'status'
  filters: { orderBy: string; direction: string }
  setFilters: (filters: { orderBy: string; direction: string }) => void
}) => {
  const selected = orderBy === field
  const newSortDirection = selected ? (direction === 'asc' ? 'desc' : 'asc') : 'asc'
  const icon = selected ? (direction === 'asc' ? HiMiniChevronUp : HiMiniChevronDown) : HiMiniChevronUpDown

  return (
    <Button
      variant="ghost"
      color="gray"
      highContrast
      className={cn('w-fit', selected && 'font-semibold')}
      onClick={() =>
        setFilters({
          orderBy: field,
          direction: newSortDirection,
        })
      }
      trailing={<Icon size="1" muted={!selected} icon={icon} />}
    >
      {children}
    </Button>
  )
}

const FileRow = ({ file }: { file: ClientReturn<'listFiles'>['files'][number] }) => {
  const { key, updatedAt, size, tags, url, contentType, status } = file
  const [showDetails, setShowDetails] = useState(false)
  const navigate = Route.useNavigate()
  const [tagsPopoverOpen, setTagsPopoverOpen] = useState(false)

  const tagCount = Object.keys(tags).length

  return (
    <>
      <Grid
        p={'2'}
        align={'center'}
        className="col-[1/-1] grid-cols-subgrid hover:cursor-pointer hover:bg-gray-2 has-[.clickable:hover]:bg-transparent"
        onClick={() => {
          setShowDetails((prev) => !prev)
        }}
      >
        <FileIcon size="2" className="-mr-8" variant={'surface'} type={contentType} />
        <Text truncate>{key}</Text>
        <Text className="flex-none" wrap={'nowrap'}>
          {formatters.byte(size ?? 0)}
        </Text>
        <Text className="flex-none" wrap={'nowrap'} color="gray">
          {DateTime.fromISO(updatedAt).toRelative()}
        </Text>
        <Popover.Root open={tagsPopoverOpen} onOpenChange={(open) => setTagsPopoverOpen(open)}>
          <Popover.Trigger disabled={!tagCount}>
            <Button variant="ghost" size={'1'} className="clickable w-fit" onClick={(e) => e.stopPropagation()}>
              {tagCount} {tagCount === 1 ? 'Tag' : 'Tags'}
            </Button>
          </Popover.Trigger>
          <Popover.Content onClick={(e) => e.stopPropagation()} size={'1'} sideOffset={5} side="bottom" align="start">
            <DataListRoot size={'1'}>
              {Object.entries(tags).map(([tag, value]) => (
                <DataListItem highContrast key={tag} label={tag} className="font-semibold">
                  <Button
                    size={'1'}
                    variant="ghost"
                    highContrast
                    color="gray"
                    trailing={<Icon size="1" icon={HiOutlineMagnifyingGlass} />}
                    onClick={() => {
                      void navigate({ search: (prev) => ({ ...prev, tags: { ...prev?.tags, [tag]: value } }) })
                      setTagsPopoverOpen(false)
                    }}
                  >
                    {value}
                  </Button>
                </DataListItem>
              ))}
            </DataListRoot>
          </Popover.Content>
        </Popover.Root>
        <FileStatusBadge status={status} className="mx-auto w-fit" />
        <DropdownMenu
          color="gray"
          onClick={(e) => e.stopPropagation()}
          content={[
            {
              type: 'item',
              content: (
                <a href={url} target="_blank" rel="noreferrer" download>
                  Download
                </a>
              ),
            },
          ]}
        >
          <IconButton
            variant="minimal"
            color="gray"
            className="clickable ml-auto"
            onClick={(e) => e.stopPropagation()}
            size={'1'}
            icon={HiEllipsisHorizontal}
          />
        </DropdownMenu>
      </Grid>
      {showDetails ? (
        <>
          <Separator size={'4'} className="col-[1/-1]" />
          <Box p={'4'} className="col-[1/-1]">
            <JSONTree hideRoot data={file} />
          </Box>
        </>
      ) : null}
    </>
  )
}
