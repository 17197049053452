import { TextBlock, type CommonBlockProps } from '../../../types'
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'

import { ComponentProps } from 'react'
import { webchatClasses } from '../../../styles/classes'

type Props = TextBlock & CommonBlockProps
export const Text = ({ text }: Props) => {
  const {
    message: {
      blocks: { text: markdownStyles },
    },
  } = webchatClasses

  const markdownComponents: ComponentProps<typeof ReactMarkdown>['components'] = {
    h1: ({ node, ...props }) => <h1 {...props} {...markdownStyles?.heading1} />,
    h2: ({ node, ...props }) => <h2 {...props} {...markdownStyles?.heading2} />,
    h3: ({ node, ...props }) => <h3 {...props} {...markdownStyles?.heading3} />,
    h4: 'h3',
    h5: 'h3',
    h6: 'h3',
    em: ({ node, ...props }) => <em {...props} {...markdownStyles?.italic} />,
    strong: ({ node, ...props }) => <strong {...props} {...markdownStyles?.bold} />,
    p: ({ node, ...props }) => <p {...props} {...markdownStyles?.text} />,
    hr: ({ node, ...props }) => <hr {...props} {...markdownStyles?.horizontalRule} />,
    a: ({ node, ...props }) => <a {...props} {...markdownStyles?.link} target="_blank" />,
    ol: ({ node, ...props }) => <ol {...props} {...markdownStyles?.orderedList} />,
    ul: ({ node, ...props }) => <ul {...props} {...markdownStyles?.unorderedList} />,
    li: ({ node, ...props }) => <li {...props} {...markdownStyles?.listItem} />,
    br: ({ node, ...props }) => <br {...props} {...markdownStyles?.lineBreak} />,
    pre: ({ node, ...props }) => <pre {...props} {...markdownStyles?.pre} />,
  }

  return (
    <ReactMarkdown components={markdownComponents} remarkPlugins={[remarkGfm, remarkBreaks]}>
      {text}
    </ReactMarkdown>
  )
}
